import Vue from 'vue';
import Vuex from 'vuex';
import axios from '@/plugins/axios';

Vue.use(Vuex);

const state = {
    events: []
};

const getters = {
    events: state => state.events
};

const mutations = {
    setEvents: (state, payload) => { state.events = payload; },
};

const actions = {
    async getEvents({ commit }) {
        await axios
        .get(Vue.prototype.$url_api + 'v1/calendarevents.php')
        .then(result => {
            commit('setEvents', result.data.data);
        })
        .catch(err => {
            console.log(err);
        });
    },

    async getEventsByApplication({ commit }, id) {
        // Configure request
        let requestUrl = Vue.prototype.$url_api + 'v1/calendarevents.php?application=' + id;

        // Execute request
        await axios
        .get(requestUrl)
        .then(result => {
            commit('setEvents', result.data.data);
        })
        .catch(err => {
            console.log(err);
            return false;
        });
    },

    async createEvent({ commit }, payload) {
        // Validate existance of necessary parameters
        if ( ! payload.hasOwnProperty('name') || ! payload.hasOwnProperty('start') || ! payload.hasOwnProperty('end') )
            return false;

        // Prepare request
        let requestUrl = Vue.prototype.$url_api + 'v1/calendarevents.php';
        let requestParameters = new URLSearchParams();
        requestParameters.append('name', payload.name);
        requestParameters.append('start', payload.start);
        requestParameters.append('end', payload.end);
        if (payload.hasOwnProperty('info')) requestParameters.append('info', payload.info);
        if (payload.hasOwnProperty('assocTable')) requestParameters.append('assocTable', payload.assocTable);
        if (payload.hasOwnProperty('assocReference')) requestParameters.append('assocReference', payload.assocReference);
        if (payload.hasOwnProperty('refUsers')) requestParameters.append('refUsers', payload.refUsers);
        if (payload.hasOwnProperty('refGroups')) requestParameters.append('refGroups', payload.refGroups);
        if (payload.hasOwnProperty('refTeams')) requestParameters.append('refTeams', payload.refTeams);

        // Execute request
        await axios
        .post(requestUrl, requestParameters)
        .then(result => {
            console.log(result);
            return true;
        })
        .catch(err => {
            console.log(err);
            return false;
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
