import { render, staticRenderFns } from "./TableNicknamesHistoricBalances.vue?vue&type=template&id=dcbe9532&scoped=true"
import script from "./TableNicknamesHistoricBalances.js?vue&type=script&lang=js&external"
export * from "./TableNicknamesHistoricBalances.js?vue&type=script&lang=js&external"
import style0 from "./TableNicknamesHistoricBalances.vue?vue&type=style&index=0&id=dcbe9532&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dcbe9532",
  null
  
)

export default component.exports