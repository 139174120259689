import Vue from 'vue';
import Vuex from 'vuex';
import axios from '@/plugins/axios';
import StoreMixin from '@/mixins/Store.vue';

Vue.use(Vuex);
Vue.use(StoreMixin);

const state = {
    networks: [],
    networkGameEntries: [],
    networksSharkscope: [],
    networkPnlUsers: [],
    images: [],
    networksSharkscopeById: null,
    networkBalance: null,
    networkPnlGraph: null,
    networkStatistics: null,
    networksTotal: null,
    networkPnlUsersTotal: null,
    networkGameEntriesTotal: null,
    selectedNetwork: null,
    loading: true,
    networkGameEntriesLoading: true,
    networkBalanceLoading: true,
    networkGameEntriesTotalLoading: true,
    networkPnlGraphLoading: true,
    networkPnlUsersLoading: true,
    networkStatisticsLoading: true,
    networksSharkscopeLoading: true,
    networksSharkscopeByIdLoading: true,
    error: null,
    humanError: null,
    promiseNetworks: null,
};

const getters = {
    networks: state => state.networks,
    networkGameEntries: state => state.networkGameEntries,
    networksSharkscope: state => state.networksSharkscope,
    networksSharkscopeById: state => state.networksSharkscopeById,
    images: state => state.images,
    networkBalance: state => state.networkBalance,
    networkPnlGraph: state => state.networkPnlGraph,
    networkPnlUsers: state => state.networkPnlUsers,
    networkStatistics: state => state.networkStatistics,
    networkGameEntriesTotal: state => state.networkGameEntriesTotal,
    networksTotal: state => state.networksTotal,
    networkPnlUsersTotal: state => state.networkPnlUsersTotal,
    selectedNetwork: state => state.selectedNetwork,
    loading: state => state.loading,
    networkGameEntriesLoading: state => state.networkGameEntriesLoading,
    networkBalanceLoading: state => state.networkBalanceLoading,
    networkGameEntriesTotalLoading: state => state.networkGameEntriesTotalLoading,
    networkPnlGraphLoading: state => state.networkPnlGraphLoading,
    networkPnlUsersLoading: state => state.networkPnlUsersLoading,
    networkStatisticsLoading: state => state.networkStatisticsLoading,
    networksSharkscopeLoading: state => state.networksSharkscopeLoading,
    networksSharkscopeByIdLoading: state => state.networksSharkscopeByIdLoading,
    error: state => state.error,
    humanError: state => state.humanError,
    promiseNetworks: state => state.promiseNetworks,
};

const mutations = {
    set: (state, payload) => { state.networks = payload },
    setSelectedNetwork: (state, payload) => { state.selectedNetwork = payload },
    setNetworkBalance: (state, payload) => { state.networkBalance = payload },
    setNetworkPnlGraph: (state, payload) => { state.networkPnlGraph = payload },
    setNetworkPnlUsers: (state, payload) => { state.networkPnlUsers = payload },
    setNetworkStatistics: (state, payload) => { state.networkStatistics = payload },
    setNetworkGameEntries: (state, payload) => { state.networkGameEntries = payload },
    setNetworksSharkscope: (state, payload) => { state.networksSharkscope = payload },
    setNetworksSharkscopeById: (state, payload) => { state.networksSharkscopeById = payload },
    setNetworkGameEntriesTotal: (state, payload) => { state.networkGameEntriesTotal = payload },
    setNetworksTotal: (state, payload) => { state.networksTotal = payload },
    setNetworkPnlUsersTotal: (state, payload) => { state.networkPnlUsersTotal = payload },
    setLoading: (state, payload) => { state.loading = payload },
    setNetworkGameEntriesLoading: (state, payload) => { state.networkGameEntriesLoading = payload },
    setNetworkBalanceLoading: (state, payload) => { state.networkBalanceLoading = payload },
    setNetworkGameEntriesTotalLoading: (state, payload) => { state.networkGameEntriesTotalLoading = payload },
    setNetworkPnlGraphLoading: (state, payload) => { state.networkPnlGraphLoading = payload },
    setNetworkPnlUsersLoading: (state, payload) => { state.networkPnlUsersLoading = payload },
    setNetworkStatisticsLoading: (state, payload) => { state.networkStatisticsLoading = payload },
    setNetworksSharkscopeLoading: (state, payload) => { state.networksSharkscopeLoading = payload },
    setNetworksSharkscopeByIdLoading: (state, payload) => { state.networksSharkscopeByIdLoading = payload },
    setError: (state, payload) => { state.error = payload },
    setHumanError: (state, payload) => { state.humanError = payload },
    addNetwork: (state, payload) => {
        if (!state.networks || !state.networks.length)
            state.networks = [];

        state.networks.unshift(payload);
    },
    updateNetwork: (state, payload) => {
        const index = state.networks.findIndex(d => d.id == payload.id);

        const updatedItems = [
            ...state.networks.slice(0, index),
            payload,
            ...state.networks.slice(index + 1)
        ];

        state.networks = updatedItems;
    },
    setImages: (state, payload) => { state.images = payload },
    setPromiseNetworks: (state, payload) => { state.promiseNetworks = payload },

};

const actions = {
    async create({ commit, dispatch }, payload) {
        // clear errors
        dispatch('clearError');

        // Handle payload data
        let data = JSON.stringify(payload);

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/networks/';
        let config = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }

        // Execute request & return
        let output = false;

        return axios(config)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('addNetwork', response.data.data);
                    commit('setSelectedNetwork', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                return output;
            });
    },

    async update({ commit, dispatch }, payload) {
        // clear errors
        dispatch('clearError');

        // Handle payload data
        let data = JSON.stringify(payload.body);

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/networks/' + payload.id;
        let config = {
            method: 'PUT',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }

        // Execute request & return
        let output = false;

        return axios(config)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('updateNetwork', response.data.data);
                    commit('setSelectedNetwork', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                return output;
            });
    },

    async remove({ commit, dispatch }, payload) {
        // clear errors
        dispatch('clearError');

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/networks/' + payload.id;
        let config = {
            method: 'DELETE',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
        }

        // Execute request & return
        let output = false;

        return axios(config)
            .then(function (response) {
                // 204 response status represents success
                output = response.status == 204;
                if (!output) {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                return output;
            });
    },

    async get({ commit, dispatch }, payload) {

        // avoid multiple calls
        if (state.promiseNetworks) {
            return state.promiseNetworks;
        }

        // Clear state
        dispatch('reset');

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/networks/';
        
        // Configure URL Parameters
        let parameters = payload && payload.params
            ? payload.params
            : StoreMixin.methods.getParametersDefault();
        
        // Build URL with parameters
        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, parameters);

        // Execute request & return
        let output = false;

        let request = axios.get(request_url)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('set', response.data.data);
                    commit('setNetworksTotal', response.data.total);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setLoading', false);
                return output;
            });
        
        commit('setPromiseNetworks', request);
        return request
    },

    async getNetwork({ commit, dispatch }, payload) {
        // clear errors
        dispatch('clearError');

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/networks/' + payload.id;

        // Configure URL parameters
        if (payload && payload.params) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        }

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('setSelectedNetwork', response.data.data);
                } else {
                    commit('setError', response.data.message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                output = false;
            })
            .then(function () {
                commit('setLoading', false);
                return output;
            });
    },

    async getNetworkBalance({ commit, dispatch }, payload) {
        // clear state
        dispatch('clearError');
        commit('setNetworkBalance', null);
        commit('setNetworkBalanceLoading', true);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/networks/' + payload.id + '/balance';

        // Configure URL parameters
        if (payload && payload.params) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        }

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('setNetworkBalance', response.data.data);
                } else {
                    commit('setError', response.data.message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                output = false;
            })
            .then(function () {
                commit('setNetworkBalanceLoading', false);
                return output;
            });
    },

    async getNetworkPnlGraph({ commit, dispatch }, payload) {
        // clear state
        dispatch('clearError');
        commit('setNetworkPnlGraph', null);
        commit('setNetworkPnlGraphLoading', true);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/networks/' + payload.id + '/pnl/graph';

        // Configure URL parameters
        if (payload && payload.params) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        }

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('setNetworkPnlGraph', response.data.data);
                } else {
                    commit('setError', response.data.message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                output = false;
            })
            .then(function () {
                commit('setNetworkPnlGraphLoading', false);
                return output;
            });
    },

    // Read usersPNL - Game Entries
    async getNetworkPnlUsers({ commit, dispatch }, payload) {
        // clear state
        dispatch('clearError');
        commit('setNetworkPnlUsers', []);
        commit('setNetworkPnlUsersLoading', true);
        commit('setNetworkPnlUsersTotal', null);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/networks/' + payload.id + '/gameEntries/pnl/users';

        // Configure URL parameters
        if (payload && payload.params) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        }

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('setNetworkPnlUsers', response.data.data);
                    commit('setNetworkPnlUsersTotal',  response.data.total);
                } else {
                    commit('setError', response.data.message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                output = false;
            })
            .then(function () {
                commit('setNetworkPnlUsersLoading', false);
                return output;
            });
    },

    // read Network Statistics - Game Entries
    async getNetworkStatistics({ commit, dispatch }, payload) {
        // clear state
        dispatch('clearError');
        commit('setNetworkStatistics', null);
        commit('setNetworkStatisticsLoading', true);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/networks/' + payload.id + '/gameEntries/statistics';

        // Configure URL parameters
        if (payload && payload.params) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        }

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('setNetworkStatistics', response.data.data);
                } else {
                    commit('setError', response.data.message);
                }
            })
            .catch(function (error) {
                commit('setNetworkStatistics', null);
                commit('setError', error.response ? error.response.data.message : error);
                output = false;
            })
            .then(function () {
                commit('setNetworkStatisticsLoading', false);
                return output;
            });
    },

    /**
     * Get network game entries
     */
    async getNetworkGameEntries({ commit, dispatch }, payload) {
        // Reset store to initial state
        commit('setNetworkGameEntries', []);
        commit('setNetworkGameEntriesLoading', true);
        dispatch('clearError');

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/networks/' + payload.id + '/gameEntries/';

        // Configure URL parameters
        let parameters = payload && payload.params
            ? payload.params
            : StoreMixin.methods.getParametersDefault();
        
        // Build URL with parameters
        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, parameters);

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('setNetworkGameEntries', response.data.data);
                    commit('setNetworkGameEntriesTotal', response.data.total);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setNetworkGameEntriesLoading', false);
                return output;
            });
    },

    /**
     * Get total network game entries
     */
    async getTotalNetworkGameEntries({ commit, dispatch }, payload) {
        // Reset store to initial state
        commit('setNetworkGameEntriesTotal', null);
        commit('setNetworkGameEntriesTotalLoading', true);
        dispatch('clearError');

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/networks/' + payload.id + '/gameEntries/count';

        // Formulate URL with query string with generateQueryParamsUrl mixin function
        if (payload && payload.params) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        }

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('setNetworkGameEntriesTotal', response.data.total);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setNetworkGameEntriesTotalLoading', false);
                return output;
            });
    },

    async getNetworksSharkscope({ commit, dispatch }, payload) {
        // Clear state
        dispatch('clearError');
        commit('setNetworksSharkscope', []);
        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/networksSharkscope/';

        // Configure URL parameters
        if (payload && payload.params) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        }

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('setNetworksSharkscope', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setNetworksSharkscopeLoading', false);
                return output;
            });
    },

    async getNetworksSharkscopeById({ commit, dispatch }, payload) {
        // Clear state
        dispatch('clearError');
        commit('setNetworksSharkscopeById', null);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/networksSharkscope/' + payload.id;

        // Configure URL parameters
        if (payload && payload.params) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        }

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('setNetworksSharkscopeById', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setNetworksSharkscopeByIdLoading', false);
                return output;
            });
    },

    /**
     * Get file names from assets/networks and push them to an array
     * 
     */
    async getImages({ commit }) {
        const images = [];
        const r = require.context('../../assets/networks', true, /\.png$/);

        /**
         * Foreach key from the require push the filename to images array
         * after this slice(2) to convert "./networkName_eur.png" to "networkName_eur.png"
         */
        await r.keys().forEach(filename => (images.push(filename.slice(2))));

        commit('setImages', images);
    },

    clearError({ commit }) {
        commit('setError', null);
        commit('setHumanError', null);
    },

    /**
     * Reset to initial state
     */
    reset({ commit }) {
        commit('set', []);
        commit('setNetworkGameEntries', []);
        commit('setNetworksSharkscope', []);
        commit('setNetworksSharkscopeById', null);
        commit('setNetworkBalance', null);
        commit('setNetworkPnlGraph', null);
        commit('setNetworkStatistics', null);
        commit('setNetworksTotal', null);
        commit('setNetworkGameEntriesTotal', null);
        commit('setLoading', true);
        commit('setNetworkGameEntriesLoading', true);
        commit('setNetworkBalanceLoading', true);
        commit('setNetworkGameEntriesTotalLoading', true);
        commit('setNetworkPnlGraphLoading', true);
        commit('setNetworkStatisticsLoading', true);
        commit('setNetworksSharkscopeLoading', true);
        commit('setNetworksSharkscopeByIdLoading', true);
        commit('setError', null);
        commit('setHumanError', null);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};