import { render, staticRenderFns } from "./DrawerCreateEditEvent.vue?vue&type=template&id=44b66bf8&scoped=true"
import script from "./DrawerCreateEditEvent.js?vue&type=script&lang=js&external"
export * from "./DrawerCreateEditEvent.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44b66bf8",
  null
  
)

export default component.exports