import Vue from 'vue';
import Vuex from 'vuex';
import axios from '@/plugins/axios';
import StoreMixin from '@/mixins/Store.vue';

Vue.use(Vuex);
Vue.use(StoreMixin);

const state = {
    schedules: [],
    schedulesMine: [],
    schedulesMineTotal: null,
    schedulesTotal: null,
    selectedSchedule: null,
    loading: true,
    loadingSelectedSchedule: false,
    error: null,
    humanError: null,
};

const getters = {
    schedules: state => state.schedules,
    schedulesMine: state => state.schedulesMine,
    schedulesMineTotal: state => state.schedulesMineTotal,
    schedulesTotal: state => state.schedulesTotal,
    selectedSchedule: state => state.selectedSchedule,
    loadingSelectedSchedule: state => state.loadingSelectedSchedule,
    loading: state => state.loading,
    error: state => state.error,
    humanError: state => state.humanError,
};

const mutations = {
    set: (state, payload) => { state.schedules = payload },
    setSchedulesMine: (state, payload) => { state.schedulesMine = payload },
    setSchedulesTotal: (state, payload) => { state.schedulesTotal = payload },
    setSchedulesMineTotal: (state, payload) => { state.schedulesMineTotal = payload },
    setSelectedSchedule: (state, payload) => { state.selectedSchedule = payload },
    setLoading: (state, payload) => { state.loading = payload },
    setLoadingSelectedSchedule: (state, payload) => { state.loadingSelectedSchedule = payload },
    setError: (state, payload) => { state.error = payload },
    setHumanError: (state, payload) => { state.humanError = payload },
};

const actions = {
    async get({ commit, dispatch }, payload) {
        // Clear state
        dispatch('reset');

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/schedules/';
        
        // Configure URL Parameters
        let parameters = payload && payload.params
            ? payload.params
            : StoreMixin.methods.getParametersDefault();
        
        // Build URL with parameters
        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, parameters);

        // Execute request & return
        let output = false;

        let request = axios.get(request_url)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('set', response.data.data);
                    commit('setSchedulesTotal', response.data.total);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setLoading', false);
                return output;
            });
        return request
    },

    async getMine({ commit, dispatch }, payload) {
        // Clear state
        commit('setSchedulesMine', []);
        commit('setSchedulesMineTotal', null);
        commit('setLoading', true);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/schedules/mine/';
        
        // Configure URL Parameters
        let parameters = payload && payload.params
            ? payload.params
            : StoreMixin.methods.getParametersDefault();
        
        // Build URL with parameters
        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, parameters);

        // Execute request & return
        let output = false;

        let request = axios.get(request_url)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('setSchedulesMine', response.data.data);
                    commit('setSelectedSchedule', response.data.data[0])
                    commit('setSchedulesMineTotal', response.data.total);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setLoading', false);
                return output;
            });
        return request
    },

    async getNextSchedule({ commit, dispatch }, payload) {
        // Clear state
        commit('setSchedulesMine', []);
        commit('setSchedulesMineTotal', null);
        commit('setLoading', true);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/schedules/next/';
        
        // Configure URL Parameters
        let parameters = payload && payload.params
            ? payload.params
            : StoreMixin.methods.getParametersDefault();
        
        // Build URL with parameters
        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, parameters);

        // Execute request & return
        let output = false;

        let request = axios.get(request_url)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('setSchedulesMine', response.data.data);
                    commit('setSelectedSchedule', response.data.data[0]);
                    commit('setSchedulesMineTotal', response.data.total);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setLoading', false);
                return output;
            });
        return request
    },

    async getPreviousSchedule({ commit, dispatch }, payload) {
        // Clear state
        commit('setSchedulesMine', []);
        commit('setSchedulesMineTotal', null);
        commit('setLoading', true);
        

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/schedules/previous/';
        
        // Configure URL Parameters
        let parameters = payload && payload.params
            ? payload.params
            : StoreMixin.methods.getParametersDefault();
        
        // Build URL with parameters
        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, parameters);

        // Execute request & return
        let output = false;

        let request = axios.get(request_url)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('setSchedulesMine', response.data.data);
                    commit('setSelectedSchedule', response.data.data[0]);
                    commit('setSchedulesMineTotal', response.data.total);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setLoading', false);
                return output;
            });
        return request
    },

    async getScheduleById({ commit, dispatch }, payload) {
        // clear errors
        dispatch('clearError');
        commit('setLoadingSelectedSchedule', true);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/schedules/' + payload.id;

        // Configure URL parameters
        if (payload && payload.params) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        }

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('setSelectedSchedule', response.data.data);
                } else {
                    commit('setError', response.data.message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                output = false;
            })
            .then(function () {
                commit('setLoadingSelectedSchedule', false);
                return output;
            });
    },

    clearError({ commit }) {
        commit('setError', null);
        commit('setHumanError', null);
    },

    /**
     * Reset to initial state
     */
    reset({ commit }) {
        commit('set', []);
        commit('setError', null);
        commit('setHumanError', null);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};