import { render, staticRenderFns } from "./Expenses.vue?vue&type=template&id=4bcddda6&scoped=true"
import script from "./Expenses.js?vue&type=script&lang=js&external"
export * from "./Expenses.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bcddda6",
  null
  
)

export default component.exports