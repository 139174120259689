import Vue from 'vue';
import Vuex from 'vuex';
import StoreMixin from '@/mixins/Store.vue';
import DataMixin from '@/mixins/Data.vue';
import axios from '@/plugins/axios';

Vue.use(Vuex);
Vue.use(StoreMixin);
Vue.use(DataMixin);

const state = {
    audits: [],
    auditsPending: [],
    auditsFinalized: [],
    auditGames: [],
    auditsTotal: null,
    auditGamesTotal: null,
    auditsPendingTotal: null,
    auditsFinalizedTotal: null,
    auditsLoading: false,
    auditsPendingLoading: false,
    auditsFinalizedLoading: false,
    auditEligibilityLoading: false,
    auditGamesLoading: false,
    auditEligibility: {
        eligible: false,
        nickname: null,
    },
    error: null,
    humanError: null,
    uploadError: null,
    uploadHumanError: null,
    errorStatus: null,
    selectedAudit: null,
    selectedAuditLoading: false,
    lastPayload: {}
};

const getters = {
    auditEligibility: state => state.auditEligibility,
    auditEligibilityLoading: state => state.auditEligibilityLoading,
    audits: state => state.audits,
    auditsPending: state => state.auditsPending,
    auditsFinalized: state => state.auditsFinalized,
    auditGames: state => state.auditGames,
    auditsLoading: state => state.auditsLoading,
    auditsPendingLoading: state => state.auditsPendingLoading,
    auditsFinalizedLoading: state => state.auditsFinalizedLoading,
    auditGamesLoading: state => state.auditGamesLoading,
    auditsTotal: state => state.auditsTotal,
    auditsPendingTotal: state => state.auditsPendingTotal,
    auditsFinalizedTotal: state => state.auditsFinalizedTotal,
    auditGamesTotal: state => state.auditGamesTotal,
    error: state => state.error,
    humanError: state => state.humanError,
    uploadError: state => state.uploadError,
    uploadHumanError: state => state.uploadHumanError,
    errorStatus: state => state.errorStatus,
    selectedAudit: state => state.selectedAudit,
    selectedAuditLoading: state => state.selectedAuditLoading,
};

const mutations = {
    setAudits: (state, payload) => { state.audits = payload; },
    setAuditsPending: (state, payload) => { state.auditsPending = payload; },
    setAuditsFinalized: (state, payload) => { state.auditsFinalized = payload; },
    setAuditsLoading: (state, payload) => { state.auditsLoading = payload; },
    setAuditsPendingLoading: (state, payload) => { state.auditsPendingLoading = payload; },
    setAuditsFinalizedLoading: (state, payload) => { state.auditsFinalizedLoading = payload; },
    setAuditsTotal: (state, payload) => { state.auditsTotal = payload; },
    setAuditsPendingTotal: (state, payload) => { state.auditsPendingTotal = payload; },
    setAuditsFinalizedTotal: (state, payload) => { state.auditsFinalizedTotal = payload; },
    setAuditEligibility: (state, payload) => { state.auditEligibility = payload; },
    setAuditEligibilityLoading: (state, payload) => { state.auditEligibilityLoading = payload; },
    setSelectedAudit: (state, payload) => { state.selectedAudit = payload },
    setSelectedAuditLoading: (state, payload) => { state.selectedAuditLoading = payload },
    uploadAudit: (state, payload) => {
        if (! state.audits || ! state.audits.length)
        state.audits = [];
    
        state.audits.unshift(payload);
    },
    setError: (state, payload) => { state.error = payload; },
    setHumanError: (state, payload) => { state.humanError = payload; },
    setUploadError: (state, payload) => { state.uploadError = payload; },
    setUploadHumanError: (state, payload) => { state.uploadHumanError = payload; },
    setErrorStatus: (state, payload) => { state.errorStatus = payload },
    updateAudit: (state, payload) => {
        const index = state.audits.findIndex(d => d.id == payload.id);

        const updatedItems = [
            ...state.audits.slice(0, index),
            payload,
            ...state.audits.slice(index + 1)
        ];

        state.audits = updatedItems;
    },
    setLastPayload: (state, payload) => { state.lastPayload = payload; },
    setAuditGames: (state, payload) => { state.auditGames = payload; },
    setAuditGamesTotal: (state, payload) => { state.auditGamesTotal = payload; },
    setAuditGamesLoading: (state, payload) => { state.auditGamesLoading = payload; },
}

const actions = {
    async getAudits({ commit, dispatch, state }, payload) {
        
        // validate payload objects before API Call
        let areObjectsEqual = DataMixin.methods.areObjectsEqual(state.lastPayload, payload.params);

        if (areObjectsEqual) return false;
        // save last payload
        commit('setLastPayload', payload.params);

        // declare variables
        let setItems;
        let setTotalItems;
        let setLoadingItems;
        let handleMutation = StoreMixin.methods.handleAuditMutations();

        if (payload && payload.params) {
            handleMutation = StoreMixin.methods.handleAuditMutations(payload.params['status[' + 0 + ']']);
        }

        // mutations that will be used to populate states based on status parameter
        setItems = handleMutation.items;
        setTotalItems = handleMutation.total;
        setLoadingItems = handleMutation.loading;

        // clear states
        dispatch('clearErrors');
        commit(setItems, []);
        commit(setTotalItems, 0);
        commit(setLoadingItems, true);
        
        let request_url = Vue.prototype.$url_api + 'v2/audit/';

        let parameters = payload && payload.params
            ? payload.params
            : StoreMixin.methods.getParametersDefault();

        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, parameters);

        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;
            if (output) {
                commit(setItems, response.data.data);
                commit(setTotalItems, response.data.total);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message ? response.data.human_message : 'Algo correu mal');
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            commit(setLoadingItems, false);
            return output;
        });
    },

    async checkEligibility ({ commit, dispatch }, payload) {
        commit('setAuditEligibilityLoading', true)
        commit('setAuditEligibility', null)
        
        let request_url = Vue.prototype.$url_api + 'v2/audit/check_eligibility/';

        let parameters = payload && payload.params
            ? payload.params
            : StoreMixin.methods.getParametersDefault();

        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, parameters);

        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;
            if (output) {
                commit('setAuditEligibility', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            commit('setAuditEligibilityLoading', false);
            return output;
        });
    },

    async uploadAudit({ commit, dispatch }, payload) {

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/audit/';
        let config = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: payload
        }

        // Execute request & return
        let output = false;

        return axios(config)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('uploadAudit', response.data.data);
                commit('setSelectedAudit', response.data.data);
            } else {
                commit('setUploadError', response.data.message);
                commit('setUploadHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setUploadError', error.response ? error.response.data.message : error);
            commit('setUploadHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            return output;
        });
    },

    /**
     * Used to populate Single Audit
     */
    async getSingleAudit({ commit, dispatch }, payload) {
        
        // clear state
        dispatch('clearErrors');

        // endpoint will run every 5 min. Loading state can't appear while user is in page, although the endpoint will run anyway
        if (payload.loading != false) {
            commit('setSelectedAudit', null);
            commit('setSelectedAuditLoading', true);
        }

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/audit/' + payload.id;

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setSelectedAudit', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
                commit('setErrorStatus', response.status);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            commit('setErrorStatus', error.response.status);
            output = false;
        })
        .then(function () {
            commit('setSelectedAuditLoading', false);
            return output;
        });
    },

    /**
     * Used exclusively to populate gameEntries from Single Audit
     */
    async getGamesFromSingleAudit({ commit, dispatch }, payload) {
        // clear state
        dispatch('clearErrors');
        commit('setAuditGames', [] );
        commit('setAuditGamesLoading', true);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/audit/' + payload.id;

        // Configure URL Parameters
        let parameters = payload && payload.params
            ? payload.params
            : StoreMixin.methods.getParametersDefault();


        // Build URL with parameters
        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, parameters);

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setAuditGames', response.data.data.gameEntries && response.data.data.gameEntries.data ? response.data.data.gameEntries.data : [] );
                commit('setAuditGamesTotal', response.data.data.gameEntries && response.data.data.gameEntries.total ? response.data.data.gameEntries.total : null);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
                commit('setErrorStatus', response.status);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            commit('setErrorStatus', error.response.status);
            output = false;
        })
        .then(function () {
            commit('setAuditGamesLoading', false);
            return output;
        });
    },

    async analyzeSingleAudit({ commit, dispatch }, payload) {
        // clear state
        dispatch('clearErrors');

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/audit/analyze/' + payload.id;

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setSelectedAudit', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
                commit('setErrorStatus', response.status);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            commit('setErrorStatus', error.response.status);
            output = false;
        })
        .then(function () {
            commit('setSelectedAuditLoading', false);
            return output;
        });
    },

    async updateAudit({ commit, dispatch }, payload) {

        // Handle payload data
        let data = JSON.stringify(payload.params);

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/audit/' + payload.id;
        let config = {
            method: 'PUT',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }

        // Execute request & return
        let output = false;

        return axios(config)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setSelectedAudit', response.data.data);
                commit('updateAudit', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            return output;
        });
    },

    async approveSingleAudit({ commit, dispatch }, payload) {

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/audit/' + payload.id + '/approve/';

        // Execute request & return
        let output = false;

        return axios.patch(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setSelectedAudit', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            // commit('loadings',false);
            return output;
        });
    },

    async rejectSingleAudit({ commit, dispatch }, payload) {

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/audit/' + payload.id + '/reject/';

        // Execute request & return
        let output = false;

        return axios.patch(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setSelectedAudit', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            // commit('loadings',false);
            return output;
        });
    },

    async restoreSingleAudit({ commit, dispatch }, payload) {

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/audit/' + payload.id + '/restore/';

        // Execute request & return
        let output = false;

        return axios.patch(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setSelectedAudit', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            // commit('loadings',false);
            return output;
        });
    },

    async deleteSingleAudit({ commit, dispatch }, payload) {

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/audit/' + payload.id;

        // Execute request & return
        let output = false;

        return axios.delete(request_url)
        .then(function (response) {
            // 204 response status represents success
            output = response.status == 204;

            if (output) {
                commit('setLastPayload', {});
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            // commit('loadings',false);
            return output;
        });
    },

    clearErrors({ commit }) {
        commit('setError', null);
        commit('setHumanError', null);
        commit('setErrorStatus', null);
    }

}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
