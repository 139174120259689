import axios from 'axios';
import api from '@/plugins/axios';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const state = {
  userAuthenticated: false,
  userRoles: [],
  userRolesLoading: false,
  userId: null,
  userTrackerId: null,
  userDisplayName: null,
  userEmail: null,
  userGroup: null,
  userTeams: null,
  userAvatar: null,
  loginError: null,
  userImgCutout: null,
  userIsManager: false,
  userIsRailManager: false,
  userProfitShare: null,
  userTeamsInitialData: null,
};

const getters = {
  userAuthenticated: state => state.userAuthenticated,
  userId: state => state.userId,
  userTrackerId: state => state.userTrackerId,
  userType: state => state.userType,
  userRoles: state => state.userRoles,
  userRolesLoading: state => state.userRolesLoading,
  userDisplayName: state => state.userDisplayName,
  userEmail: state => state.userEmail,
  userGroup: state => state.userGroup,
  userTeams: state => state.userTeams,
  userAvatar: state => state.userAvatar,
  loginError: state => state.loginError,
  userImgCutout: state => state.userImgCutout,
  userIsManager: state => state.userIsManager,
  userIsRailManager: state => state.userIsRailManager,
  userProfitShare: state => state.userProfitShare,
  userTeamsInitialData: state => state.userTeamsInitialData,
};

const mutations = {
  setAuthenticationStatus: (state, status) => {
    state.userAuthenticated = status;
  },

  setUserRoles: (state, payload) => {
    state.userRoles = payload;
  },

  setUserRolesLoading: (state, payload) => {
    state.userRolesLoading = payload;
  },

  setUserDisplayName: (state, payload) => {
    state.userDisplayName = payload;
  },

  setUserEmail: (state, payload) => {
    state.userEmail = payload;
  },

  setUserGroup: (state, payload) => {
    state.userGroup = payload;
  },

  setUserTeams: (state, payload) => {
    state.userTeams = payload;
  },

  setUserId: (state, payload) => {
    state.userId = payload;
  },

  setUserTrackerId: (state, payload) => {
    state.userTrackerId = payload;
  },

  setUserAvatar: (state, payload) => {
    state.userAvatar = payload;
  },

  setLoginError: (state, payload) => {
    state.loginError = payload;
  },

  setUserImgCutout: (state, payload) => {
    state.userImgCutout = payload;
  },

  setUserIsManager: (state, payload) => {
    state.userIsManager = payload;
  },

  setUserIsRailManager: (state, payload) => {
    state.userIsRailManager = payload;
  },
  setUserProfitShare: (state, payload) => {
    state.userProfitShare = payload;
  },
  setUserTeamsInitialData: (state, payload) => {
    state.userTeamsInitialData = payload;
  },
};

const actions = {
  /*
   * if token exists , validates token
  */
  async decodeToken({ commit, dispatch }) {
    let output = false;

    if ($cookies.isKey(Vue.prototype.$cookie_names.jwt_token)) {
      // Get user token
      let token = $cookies.get(Vue.prototype.$cookie_names.jwt_token);

      // Set axios header settings
      axios.defaults.headers.common['X-Api-Key'] = Vue.prototype.$x_api_key;
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

      // Send request
      return await axios.get(Vue.prototype.$url_auth_validate_jwt_token)
        .then(function (response) {
          if (response.status == 200) {
            dispatch('populateAuthUserStates', response.data.data);
            output = true;
          }
        })
        // Useful to look into data returned - return [error.response.data.message];
        .catch(function (error) {
          output = false;
        })
        .then(function () {
          return output;
        });
    }
  },

  /** Used on the login form
   * generate token (user + password generates a token)
  */
  async login({ commit, dispatch }, payload) {
    let output = false;

    // Payload must be an object containing 2 fields: username, password
    if (!payload.hasOwnProperty('username') || !payload.hasOwnProperty('password')) {
      return false;
    }

    // Set axios header settings
    axios.defaults.headers.common['X-Api-Key'] = Vue.prototype.$x_api_key;

    // Send request
    return axios.post(Vue.prototype.$url_auth_get_jwt_token, payload)
      .then(function (response) {
        if (response.status == 200) {
          dispatch('addTokenToCookies', response);
          output = true;
        }
        else {
          commit('setLoginError', { message: 'Acesso exclusivo para membros da equipa.' });
          output = false;
        }
      })
      .catch(function (error) {
        commit('setLoginError', error.response.data);
        output = false;
      })
      .then(function () {
        return output;
      });
  },

  /**
   * Updates the 'group_level' cookie every time the server value differs from the client value
   */
  setUserGroupLevelCookie({commit}, response) {
    if ($cookies.get(Vue.prototype.$cookie_names.group_level) != response.data.data.group.level) {
      $cookies.set(Vue.prototype.$cookie_names.group_level, response.data.data.group.level)
    }
  },

  addTokenToCookies({commit}, response) {
    // Save token in cookie
    // Should only happen on login() and never on authenticate(), since the token validation
    // endpoint does not retrieve any tokens.
    if (response.data.data.token) {
      if (!$cookies.isKey(Vue.prototype.$cookie_names.jwt_token) || $cookies.get(Vue.prototype.$cookie_names.jwt_token) != response.data.data.token)
        $cookies.set(Vue.prototype.$cookie_names.jwt_token, response.data.data.token)	//this.$cookies.set(Vue.prototype.$cookie_names.jwt_token, response.data.token, '30d', null, window.location.hostname, true)
    }
  },

  populateAuthUserStates({ dispatch, commit }, data) {    
    // Set authenticated status
    commit('setAuthenticationStatus', true);
    commit('setUserId', data.user.wpID);
    commit('setUserTrackerId', data.user.id);
    commit('setUserAvatar', data.user.avatar);
    commit('setUserTeamsInitialData', data.user.teams);
    commit('setLoginError', null);
    
    dispatch('getUser', data.user.id);
    dispatch('getUserRoles', data.user.id);
  },

  async getUser({ commit, dispatch }, payload) {
    // Configure request
    const request_url = Vue.prototype.$url_api + 'v2/users/' + payload;

    // Execute request & return
    let output = false;

    return api.get(request_url)
    .then(function (response) {
        output = response.data.success;

        if (output) {
          commit('setUserDisplayName', response.data.data.displayName);
          commit('setUserEmail', response.data.data.email);
          commit('setUserGroup', response.data.data.group);
          commit('setUserTeams', response.data.data.teams);
          commit('setUserImgCutout', response.data.data.imgCutout);
          commit('setUserProfitShare', response.data.data.profitShare);

          // Store user group cookie for DialogAlertLevelChange
          dispatch('setUserGroupLevelCookie', response);
        }
    })
    .catch(function (error) {
        output = false;
    })
    .then(function () {
        return output;
    });
  },

  async getUserRoles({ commit, dispatch }, userId=null) {
    commit('setUserRolesLoading', true);

    // Default to requesting user if none is specified
    if (! userId) {
      userId = state.userTrackerId;
    }

    // Configure request
    const request_url = Vue.prototype.$url_api + 'v2/users/' + userId + '/roles';

    // Execute request & return
    let output = false;

    return await axios.get(request_url)
    .then(function (response) {
        output = response.data.success;
        if (output) {
          commit('setUserRoles', response.data.data);

          commit('setUserIsManager', ! _.isEmpty( this._.intersection(response.data.data, ['rail_admin', 'rail_manager', 'tracker_admin', 'tracker_manager']) ));
          commit('setUserIsRailManager', ! _.isEmpty( this._.intersection(response.data.data, ['rail_admin', 'rail_manager']) ));
        }
    })
    .catch(function (error) {
        output = false;
    })
    .then(function () {
      commit('setUserRolesLoading', false);
      return output;
    });
  },

  logout({ dispatch }) {
    // Clear cookies
    dispatch('clearCookies');

    // Clear Axios headers
    dispatch('clearAxios');

    // reset stores
    dispatch('resetStores')

    // Clear session store
    dispatch('clearSession');
  },

  resetStores({ dispatch }) {
    dispatch('nicknames/reset', null, { root: true });
    dispatch('users/reset', null, { root: true });
  },

  clearSession({ commit }) {
    commit('setAuthenticationStatus', false);
    commit('setUserId', null);
    commit('setUserTrackerId', null);
    commit('setUserRoles', []);
    commit('setUserDisplayName', null);
    commit('setUserEmail', null);
    commit('setUserGroup', null);
    commit('setUserTeams', null);
    commit('setUserAvatar', null);
    commit('setLoginError', null);
    commit('setUserImgCutout', null);
    commit('setUserProfitShare', null);
  },

  /**
   * Clears all cookies but the ones in the whitelist
   */
  clearCookies() {
    Object.keys(Vue.prototype.$cookie_names).forEach((e) => {
      if (! Vue.prototype.$cookie_whitelist.includes(e)) {
        $cookies.remove(Vue.prototype.$cookie_names[e]);
      }
    });
  },

  clearAxios() {
    delete axios.defaults.headers.common['Authorization'];
    delete axios.defaults.headers.common['X-Api-Key'];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};