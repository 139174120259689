import axios from '@/plugins/axios';
import Vue from 'vue';
import Vuex from 'vuex';
import StoreMixin from '@/mixins/Store.vue';

Vue.use(Vuex);
Vue.use(StoreMixin);

const state = {
    expenses: [],
    exportedExpenses: [],
    expenseTypes: [],
    graph: null,
    selectedExpense: null,
    selectedType: null,
    loading: true,
    loadingGraph: true,
    loadingExpenseTypes: true,
    error: null,
    humanError: null,
    totalExpenses: null,
    isExpense: false,
};

const getters = {
    expenses: (state) => state.expenses,
    exportedExpenses: (state) => state.exportedExpenses,
    expenseTypes: (state) => state.expenseTypes,
    graph: (state) => state.graph,
    selectedExpense: (state) => state.selectedExpense,
    selectedType: (state) => state.selectedType,
    loading: (state) => state.loading,
    loadingGraph: (state) => state.loadingGraph,
    loadingExpenseTypes: (state) => state.loadingExpenseTypes,
    error: (state) => state.error,
    humanError: (state) => state.humanError,
    totalExpenses: (state) => state.totalExpenses,
    isExpense: (state) => state.isExpense,
};

const mutations = {
    setExpenses(state, payload) {
        state.expenses = payload;
    },
    setExportedExpenses(state, payload) {
        state.exportedExpenses = payload;
    },
    setExpenseTypes(state, payload) {
        state.expenseTypes = payload;
    },
    setGraph(state, payload) {
        state.graph = payload;
    },
    setSelectedExpense(state, payload) {
        state.selectedExpense = payload;
    },
    setSelectedType(state, payload) {
        state.selectedType = payload;
    },
    setLoading(state, payload) {
        state.loading = payload;
    },
    setLoadingGraph(state, payload) {
        state.loadingGraph = payload;
    },
    setLoadingExpenseTypes(state, payload) {
        state.loadingExpenseTypes = payload;
    },
    setError(state, payload) {
        state.error = payload;
    },
    setHumanError(state, payload) {
        state.humanError = payload;
    },
    addExpense(state, payload) {
        if (!state.expenses || !Array.isArray(state.expenses))
            state.expenses = [];
        state.expenses.unshift(payload);
    },
    updateExpense: (state, payload) => {
        const expense = state.expenses.filter(
            i => i.id == payload.id
        )[0];

        const index = state.expenses.findIndex(
            i => i.id == payload.id
        );

        const updatedItems = [
            ...state.expenses.slice(0, index),
            expense,
            ...state.expenses.slice(index + 1)
        ];

        state.expenses = updatedItems;
    },
    deleteExpense: (state, payload) => {
        const index = state.expenses.findIndex(
            i => i.id == payload.id
        );
        state.expenses.splice(index, 1);
    },
    addExpenseType(state, payload) {
        if (!state.expenseTypes || !Array.isArray(state.expenseTypes))
            state.expenseTypes = [];
        state.expenseTypes.unshift(payload);
    },
    updateExpenseType: (state, payload) => {
        const expenseType = state.expenseTypes.filter(
            i => i.id == payload.id
        )[0];

        const index = state.expenseTypes.findIndex(
            i => i.id == payload.id
        );

        const updatedItems = [
            ...state.expenseTypes.slice(0, index),
            expenseType,
            ...state.expenseTypes.slice(index + 1)
        ];

        state.expenses = updatedItems;
    },
    deleteExpenseType: (state, payload) => {
        const index = state.expenseTypes.findIndex(
            i => i.id == payload.id
        );
        state.expenseTypes.splice(index, 1);
    },
    setTotalExpenses(state, payload) {
        state.totalExpenses = payload;
    },
    setIsExpense(state, payload) {
        state.isExpense = payload;
    },
};

const actions = {
    /**
     * Expenses
     */

    async getExpenses({ commit }, payload) {
        // clear state
        commit('setExpenses', []);
        commit('setTotalExpenses', null);
        commit('setLoading', true);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/rails/expenses/';

        // Formulate URL with query string with generateQueryParamsUrl mixin function
        if (payload && payload.params) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        }

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then((response) => {
            output = response.data.success;

            if (output) {
                if (payload && payload.export) {
                    commit('setExportedExpenses', response.data.data);
                } else {
                    commit('setTotalExpenses', response.data.total);
                    commit('setExpenses', response.data.data);
                }
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            commit('setLoading', false);
            return output;
        });
    },

    async createExpense({ commit, dispatch }, payload) {
        dispatch('clearError');

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/rails/expenses/';

        var requestConfig = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload
        }

        // Execute request & return
        let output = false;

        return axios(requestConfig)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('addExpense', response.data.data);
                commit('setSelectedExpense', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(error => {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            return output;
        });
    },

    async updateExpense({ commit, dispatch }, payload) {
        dispatch('clearError');

        // Handle payload data
        let data = JSON.stringify(payload.body);

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/rails/expenses/' + payload.id;
        let config = {
            method: 'PUT',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data,
        }

        // Execute request & return
        let output = false;

        return axios(config)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('updateExpense', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            return output;
        });
    },

    async deleteExpense({ commit, dispatch }, payload) {
        dispatch('clearError');

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/rails/expenses/' + payload.id;

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('deleteExpense', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            return output;
        });
    },

    /**
     * Expense Types
     */

    async getTypes({ commit, dispatch }, payload) {
        // clear state
        commit('setExpenseTypes', []);
        commit('setLoadingExpenseTypes', true);

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/rails/expenses/types/';

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then((response) => {
            output = response.data.success;

            if (output) {
                commit('setExpenseTypes', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            commit('setLoadingExpenseTypes', false);
            return output;
        });
    },

    async createType({ commit, dispatch }, payload) {
        dispatch('clearError');

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/rails/expenses/types/';

        var requestConfig = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload
        }

        // Execute request & return
        let output = false;

        return axios(requestConfig)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('addExpenseType', response.data.data);
                commit('setSelectedType', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(error => {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            return output;
        });
    },

    async updateType({ commit, dispatch }, payload) {
        dispatch('clearError');

        // Handle payload data
        let data = JSON.stringify(payload.body);

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/rails/expenses/types/' + payload.id;
        let config = {
            method: 'PUT',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data,
        }

        // Execute request & return
        let output = false;

        return axios(config)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('updateExpenseType', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            return output;
        });
    },

    async deleteType({ commit, dispatch }, id) {
        dispatch('clearError');

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/rails/expenses/types/' + id;

        let config = {
            method: 'DELETE',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
        }

        // Execute request & return
        let output = false;

        return axios(config)
        .then(function (response) {
            // 204 response status represents success
            output = response.status == 204;
            if (! output) {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            return output;
        });
    },

    reset({ commit }) {
        commit('setExpenses', []);
        commit('setExportedExpenses', []);
        commit('setExpenseTypes', []);
        commit('setGraph', null);
        commit('setSelectedExpense', null);
        commit('setSelectedType', null);
        commit('setLoading', true);
        commit('setLoadingGraph', true);
        commit('setLoadingExpenseTypes', true);
        commit('setError', null);
        commit('setHumanError', null);
    },

    clearError({ commit }) {
        commit('setError', null);
        commit('setHumanError', null);
    },

    /**
     * Graph
     */
    
    async getGraph({ commit, dispatch }, payload) {
        // clear state
        commit('setGraph', null);
        commit('setLoadingGraph', true);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/rails/expenses/graph/';

        // Formulate URL with query string with generateQueryParamsUrl mixin function
        if (payload && payload.params) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        }

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then((response) => {
            output = response.data.success;

            if (output) {
                commit('setGraph', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            commit('setLoadingGraph', false);
            return output;
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
