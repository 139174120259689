import axios from '@/plugins/axios';
import DataMixin from "@/mixins/Data";
import LineChartDualY from "@/components/Statistics/LineChartDualY";
import PieChart from "@/components/Statistics/PieChart";
import { getMetadata } from 'core-js/fn/reflect';

export default {
  name: 'VideoStatistics',

  components: {
    LineChartDualY,
    PieChart,
  },

  mixins: [DataMixin],

  data() {
    return {
      dateFrom: null,
      dateTo: new Date().toISOString().substr(0, 10),
      menuDateFrom: false,
      menuDateTo: false,
      itemsFilterType: ['Equipa', 'Grupo', 'Jogador', 'Limpar'],
      itemsFilterValue: [],
      filter: {
          type: null,
          value: null,
          value_id: null,
          value_data: null
      },
      filterType: null,
      filterValue: null,
      filterValueId: null,
      filterValueData: null,
      apiFilterType: null,
      apiDataIdField: null,
      apiDataNameField: null,
      data_all_charts: null,
      chart_1: {
        headers: ['Data', 'Vídeos pedidos', 'Pedidos aprovados'],
        data: [],
        hasLoaded: false
      },
      chart_2: {
        headers: ['Data', 'Relatórios entregues', 'Relatórios avaliados'],
        data: [],
        hasLoaded: false
      },
      chart_3: {
        headers: ['Número de pedidos de vídeo', 'Número de relatórios entregues'],
        data: [],
        hasLoaded: false
      },
      chart_4: {
        headers: ['Reports entregues a tempo', 'Reports entregues em atraso'],
        data: [],
        hasLoaded: false
      }
    };
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
        // Fill 'date from' field
        let dateCurrent = new Date();
        this.dateFrom = this.generateHtmlFormatDateString(
            this.getDateFirstOfTheMonth(dateCurrent)
        );

        // Fetch general data
        this.fetchChartData();
    },

    /**
     * Request video statistics from API with filters 
     * mandatory state values (dateFrom, dateTo)
     * optional state values (filterType and filterValue)
     */
    async fetchChartData() {
        // Base URL
        let requestUrl = `${this.$url_api}routes/video_statistics.php?date_from=${this.dateFrom}&date_to=${this.dateTo}`;
        // Add filter type and value, if defined
        if (this.filter.type && this.filterValue)
            requestUrl += `&filter_type=${this.apiFilterType}&filter_value=${this.filterValueId}`;
        
        await axios.get(requestUrl).then(response => {
            // validate if response is valid
            if (response && response.data.success && response.data.data)
                this.data_all_charts = response.data.data;
            
            return true;
        });
    },

    async loadData() {
        this.clearCharts();
        await this.fetchChartData();
        this.loadChart1();
        this.loadChart2();
        this.loadChart3();
        this.loadChart4();
    },

    async loadChart1() {
        // Reset to initial state
        this.chart_1.hasLoaded = false;
        this.chart_1.data = [];

        // Fetch data if not already loaded
        if (!this.data_all_charts)
            await this.fetchChartData();

        // No data scenario
        if (! this.data_all_charts.video_requests_stats || ! Object.keys(this.data_all_charts.video_requests_stats).length) {
            this.chart_1.hasLoaded = true;
            return false;
        }

        // Add header in arr position 0 (as per GCharts spec)
        this.chart_1.data.push(this.chart_1.headers);

        // Add fetched data
        if (Object.keys(this.data_all_charts.video_requests_stats).length) {
            for (const [key, value] of Object.entries(this.data_all_charts.video_requests_stats))
                this.chart_1.data.push([key, value.total, value.approved]);
        }

        // Show chart
        this.chart_1.hasLoaded = true;
    },

    async loadChart2() {
        // Reset to initial state
        this.chart_2.hasLoaded = false;
        this.chart_2.data = [];

        // Fetch data if not already loaded
        if (!this.data_all_charts)
            await this.fetchChartData();

        // No data scenario
        if (! this.data_all_charts.video_reports_stats || ! Object.keys(this.data_all_charts.video_reports_stats).length) {
            this.chart_2.hasLoaded = true;
            return false;
        }

        // Add header in arr position 0 (as per GCharts spec)
        this.chart_2.data.push(this.chart_1.headers);

        // Add fetched data
        if (Object.keys(this.data_all_charts.video_reports_stats).length) {
            for (const [key, value] of Object.entries(this.data_all_charts.video_reports_stats))
                this.chart_2.data.push([key, value.total, value.reviewed]);
        }

        // Show chart
        this.chart_2.hasLoaded = true;
    },

    async loadChart3() {
        // Reset to initial state
        this.chart_3.hasLoaded = false;
        this.chart_3.data = [];

        // Fetch data if not already loaded
        if (!this.data_all_charts)
            await this.fetchChartData();

        // No data scenario
        if (
            ! this.data_all_charts.reports_delivered_vs_nondelivered ||
            ! Object.keys(this.data_all_charts.reports_delivered_vs_nondelivered).length ||
            (this.data_all_charts.reports_delivered_vs_nondelivered.delivered == 0 && this.data_all_charts.reports_delivered_vs_nondelivered.not_delivered == 0)
        ) {
            this.chart_3.hasLoaded = true;
            return false;
        }

        // Add header in arr position 0 (as per GCharts spec)
        this.chart_3.data.push(this.chart_3.headers);
        
        // Add fetched data
        if (Object.keys(this.data_all_charts.reports_delivered_vs_nondelivered).length) {
            for (const [key, value] of Object.entries(this.data_all_charts.reports_delivered_vs_nondelivered))
                this.chart_3.data.push([key, value]);
        }

        // Show chart
        this.chart_3.hasLoaded = true;
    },

    async loadChart4() {
        // Reset to initial state
        this.chart_4.hasLoaded = false;
        this.chart_4.data = [];

        // Fetch data if not already loaded
        if (!this.data_all_charts)
            await this.fetchChartData();

        // No data scenario
        if (
            ! this.data_all_charts.reports_timely_delivery ||
            ! Object.keys(this.data_all_charts.reports_timely_delivery).length ||
            (this.data_all_charts.reports_timely_delivery.on_time == 0 && this.data_all_charts.reports_timely_delivery.off_time == 0)
        ) {
            this.chart_4.hasLoaded = true;
            return false;
        }

        // Add header in arr position 0 (as per GCharts spec)
        this.chart_4.data.push(this.chart_4.headers);
        
        // Add fetched data
        if (Object.keys(this.data_all_charts.reports_timely_delivery).length) {
            for (const [key, value] of Object.entries(this.data_all_charts.reports_timely_delivery))
                this.chart_4.data.push([key, value]);
        }

        // Show chart
        this.chart_4.hasLoaded = true;
    },

    fetchFilterValues() {
        // Clear past data
        this.clearFilter();

        if (this.filter.type == 'Limpar') {
            this.filter.type = null;
            this.data_all_charts = null;
            this.loadData();
            return false;
        }

        // Convert filter type to api filter type & endpoint/route
        const apiRouteName = this.adaptFilterType();
        const apiEndpoint = `${this.$url_api}routes/${apiRouteName}.php`;

        // Update filter value id with the filter types that the API knows
        this.apiFilterType = apiRouteName.slice(0,-1);

        // Set field name of the api response for the multiple filter types (e.g. name_users, name_networks, ...)
        // Used for pushing to itemsFilterValue array and for updating the 'apiDataNameField' var
        this.apiDataIdField = 'id_' + apiRouteName;   // Create adaptFilterId() function if necessary
        this.apiDataNameField = this.adaptFilterValue(apiRouteName);

        // API request
        axios.get(apiEndpoint).then(response => {
            if (!response.data.success)
                return;

            // Keep full data (such as ID) in array
            this.filterValueData = response.data.data;

            // Push only options names to array
            this.itemsFilterValue = [];
            for (let key in this.filterValueData)
            this.itemsFilterValue.push(
                this.filterValueData[key][this.apiDataNameField]
            );
        });
    },

    clearFilter() {
        this.itemsFilterValue = [];
        this.filterValueData = null;
        this.apiDataIdField = null;
        this.apiDataNameField = null;
    },

    clearCharts() {
        this.chart_1.hasLoaded = false;
        this.chart_2.hasLoaded = false;
        this.chart_3.hasLoaded = false;
        this.chart_4.hasLoaded = false;
        this.chart_1.data = [];
        this.chart_2.data = [];
        this.chart_3.data = [];
        this.chart_4.data = [];
    },

    /**
     * Translate "Filter type" input value into API route.
     * 
     * @param   {String}  this.filterType v-model of the "Filter type" input.
     * @return  {String}  Name of API route.
     */
    adaptFilterType() {
         switch (this.filter.type) {
            case 'Equipa':
                return 'teams';
            case 'Grupo':
                return 'groups';
            case 'Jogador':
                return 'users';
            default:
                return ''
        }
    },

    /**
     * Translate name of API route into key with which we populate the "Filter value" dropdown.
     * 
     * @param   {String}    filterType
     * @return  {String}    Key from which to populate the dropdown.
     */
    adaptFilterValue(filterType) {
        switch (filterType) {
            case 'teams':
                return 'name_teams';
            case 'groups':
                return 'name_groups';
            case 'users':
                return 'display_name_users';
            default:
                return ''
        }
    }
  },
};