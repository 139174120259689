import { render, staticRenderFns } from "./CardReport.vue?vue&type=template&id=2587db66&scoped=true"
import script from "./CardReport.js?vue&type=script&lang=js&external"
export * from "./CardReport.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2587db66",
  null
  
)

export default component.exports