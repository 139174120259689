import Vue from 'vue';
import Vuex from 'vuex';
import axios from '@/plugins/axios';

Vue.use(Vuex);

const state = {
    requestedVideos: [],
    videos: [],
    selectedVideo: null,
    feedback: null,
    hasLoaded: false,
};

const getters = {
    requestedVideos: (state) => state.requestedVideos,
    selectedVideo: (state) => state.selectedVideo,
    videos: (state) => state.videos,
    feedback: (state) => state.feedback,
    hasLoaded: (state) => state.hasLoaded,
};

const mutations = {
    setRequestedVideos(state, payload) {
        state.requestedVideos = payload;
    },
    setSelectedVideo: (state, payload) => {
        state.selectedVideo = payload;
    },
    addRequestVideo: (state, payload) => {
        if (!state.requestedVideos || !state.requestedVideos.length)
            state.requestedVideos = [];
        state.requestedVideos.unshift(payload);
    },
    setVideos(state, payload) {
        state.videos = payload;
    },
    updateRequestVideos: (state, payload) => {
        const index = state.requestedVideos.findIndex(
            (d) => d.id_video_requests == payload.id_video_requests
        );

        const updatedItems = [
            ...state.requestedVideos.slice(0, index),
            payload,
            ...state.requestedVideos.slice(index + 1),
        ];

        state.requestedVideos = updatedItems;
    },
    feedbackStatus(state, payload) {
        state.feedback = payload;
    },
    setHasLoaded(state, payload) {
        state.hasLoaded = payload;
    },
};

const actions = {
    // Get video requests
    async getRequestVideos({ commit }, payload) {
        // Reset to initial state
        commit('setRequestedVideos', []);
        commit('setHasLoaded', false);

        // Build query string
        let params = new URLSearchParams(payload);
        if (params.toString() != '')
            params = '?' + params;

        // API request
        const request_url = Vue.prototype.$url_api + 'v1/video_requests.php' + params;
        await axios
            .get(request_url)
            .then((result) => {
                commit('setRequestedVideos', result.data.data);
                commit('setHasLoaded', true);
                return true;
            })
            .catch((err) => {
                return false;
            });
    },

    // Get WordPress Videos
    async getVideos({ commit }, payload) {
        const request_url = Vue.prototype.$url_api + 'v1/video_requests.php?view=list_videos';

        await axios
            .get(request_url)
            .then((result) => {
                commit('setVideos', result.data.data);
                return true;
            })
            .catch((err) => {
                return false;
            });
    },

    /**
     * Create a video request (admin or player) using API
     * @param {Object} commit - store mutation
     * @param {Object} payload - video request object expected by the API
     * @returns true (video request created with success)
     * @returns Error (video request creation failed due API validation)
     */
    async createRequestVideo({ commit }, payload) {
        const request_url = Vue.prototype.$url_api + 'v1/video_requests.php';

        // Convert payload to urlsearchparams
        let params = new URLSearchParams();
        if (payload.hasOwnProperty('post'))
            params.append('post', payload.post);
        if (payload.hasOwnProperty('user'))
            params.append('user', payload.user);
        if (payload.hasOwnProperty('status'))
            params.append('status', payload.status);
        if (payload.hasOwnProperty('expiry') && payload.hasOwnProperty('status') && payload.status == 1)
            params.append('expiry', payload.expiry);

        return new Promise((resolve, reject) => {
            return axios
                .post(request_url, params)
                .then(result => {
                    commit('feedbackStatus', result);
                    if (result.data.success) {
                        commit('addRequestVideo', result.data.data);
                        resolve(true);
                    } else {
                        throw new Error(result.data.message);
                    }
                })
                .catch(err => {
                    reject(err)
                });
        })
    },

    // Update a video request Ex: approve or reject, extend time (admin)
    async updateRequestVideo({ commit }, payload) {
        const request_url = Vue.prototype.$url_api + 'v1/video_requests.php?id=' + payload.id;

        // Convert payload to urlsearchparams
        let params = new URLSearchParams();
        if (payload.hasOwnProperty('post'))
            params.append('post', payload.post);
        if (payload.hasOwnProperty('user'))
            params.append('user', payload.user);
        if (payload.hasOwnProperty('status'))
            params.append('status', payload.status);
        if (payload.hasOwnProperty('expiry'))
            params.append('expiry', payload.expiry);

        // Request
        await axios
            .put(request_url, params)
            .then((result) => {
                commit('updateRequestVideos', result.data.data);
                return true;
            })
            .catch((err) => {
                return false;
            });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
