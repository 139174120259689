import axios from '@/plugins/axios';
import Vue from 'vue';
import Vuex from 'vuex';
import StoreMixin from '@/mixins/Store.vue';

Vue.use(Vuex);
Vue.use(StoreMixin);

const state = {
    requests: [],
    ongoingRequests: [],
    finalizedRequests: [],
    exportedRequests: [],
    operationEvents: [],
    operationsLegacyList: [],
    selectedRequest: null,
    selectedRequestLegacy: null,
    hasLoaded: false,
    hasLoadedOngoingRequests: false,
    hasLoadedFinalizedRequests: false,
    hasLoadedOperationEvents: false,
    hasLoadedOperationsLegacyList: false,
    error: null,
    humanError: null,
    totalOngoingRequests: null,
    totalFinalizedRequests: null,
    totalRequests: null,
    currentPayloadEventOperationId: null,
    totalOperationsLegacyList: null,
};

const getters = {
    requests: state => state.requests,
    ongoingRequests: state => state.ongoingRequests,
    finalizedRequests: state => state.finalizedRequests,
    exportedRequests: state => state.exportedRequests,
    operationEvents: state => state.operationEvents,
    operationsLegacyList: state => state.operationsLegacyList,
    selectedRequest: state => state.selectedRequest,
    selectedRequestLegacy: state => state.selectedRequestLegacy,
    hasLoaded: state => state.hasLoaded,
    hasLoadedOngoingRequests: state => state.hasLoadedOngoingRequests,
    hasLoadedFinalizedRequests: state => state.hasLoadedFinalizedRequests,
    hasLoadedOperationEvents: state => state.hasLoadedOperationEvents,
    hasLoadedOperationsLegacyList: state => state.hasLoadedOperationsLegacyList,
    error: state => state.error,
    humanError: state => state.humanError,
    totalOngoingRequests: state => state.totalOngoingRequests,
    totalFinalizedRequests: state => state.totalFinalizedRequests,
    totalRequests: state => state.totalRequests,
    totalOperationsLegacyList: state => state.totalOperationsLegacyList
};

const mutations = {
    setRequests(state, payload) {
        state.requests = payload;
    },

    setOngoingRequests(state, payload) {
        state.ongoingRequests = payload;
    },
    
    setFinalizedRequests(state, payload) {
        state.finalizedRequests = payload;
    },

    setExportedRequests(state, payload) {
        state.exportedRequests = payload;
    },

    setOperationsLegacyList(state, payload) {
        state.operationsLegacyList = payload;
    },

    setSelectedRequest(state, payload) {
        state.selectedRequest = payload;
    },

    setSelectedRequestLegacy(state, payload) {
        state.selectedRequestLegacy = payload;
    },

    setHasLoaded(state, payload) {
        state.hasLoaded = payload;
    },

    setHasLoadedOngoingRequests(state, payload) {
        state.hasLoadedOngoingRequests = payload;
    },

    setHasLoadedFinalizedRequests(state, payload) {
        state.hasLoadedFinalizedRequests = payload;
    },

    setHasLoadedOperationsLegacyList(state, payload) {
        state.hasLoadedOperationsLegacyList = payload;
    },

    add(state, payload) {
        if (!state.requests || !Array.isArray(state.requests))
            state.requests = [];
        state.requests.unshift(payload);
    },

    update: (state, payload) => {
        const request = state.requests.filter(
            i => i.id == payload.id
        )[0];

        const index = state.requests.findIndex(
            i => i.id == payload.id
        );

        const updatedItems = [
            ...state.requests.slice(0, index),
            request,
            ...state.requests.slice(index + 1)
        ];

        state.requests = updatedItems;
    },

    delete(state, payload) {
        const index = state.requests.findIndex(
            i => i.id == payload
        );

        state.requests.splice(index, 1);
    },

    setError(state, payload) {
        state.error = payload;
    },

    setHumanError(state, payload) {
        state.humanError = payload;
    },

    setTotalRequests(state, payload) {
        state.totalRequests = payload;
    },

    setTotalOngoingRequests(state, payload) {
        state.totalOngoingRequests = payload;
    },

    setTotalFinalizedRequests(state, payload) {
        state.totalFinalizedRequests = payload;
    },

    setTotalOperationsLegacyList(state, payload) {
        state.totalOperationsLegacyList = payload;
    },

    setOperationEvents(state, payload) {
        state.operationEvents = payload;
    },
    setHasLoadedOperationEvents(state, payload) {
        state.hasLoadedOperationEvents = payload;
    },
    setCurrentPayloadEventOperationId(state, payload) {
        state.currentPayloadEventOperationId = payload;
    }
};

const actions = {
    async getRequests({ commit, dispatch }, payload) {
        // Declare variables
        let setItems;
        let setTotalItems;
        let setLoadingItems;

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/rails';

        // Configure URL parameters
        if (payload && payload.params) {
            // Delete 'ongoing' param if necessary (TableRequests Tab 'All')
            if (_.isNull(payload.params.ongoing)) {
                delete payload.params.ongoing;
            }

            // Formulate URL with query string with generateQueryParamsUrl mixin function
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        }

        // Mutations that will be used to populate states based on ongoing parameter
        let handleMutation = StoreMixin.methods.handleRailRequestsMutations(payload.params);
        setItems = handleMutation.requests;
        setTotalItems = handleMutation.total;
        setLoadingItems = handleMutation.loading;

        // Clear states
        commit(setItems, []);
        commit(setTotalItems, 0);
        commit(setLoadingItems, false);
        dispatch('clearError');

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    if (payload && payload.export) {
                        commit('setExportedRequests', response.data.data);
                    } else {
                        commit(setItems, response.data.data);
                        commit(setTotalItems, response.data.total);
                    }
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                commit(setItems, []);
                commit(setTotalItems, 0);
                output = false;
            })
            .then(function () {
                commit(setLoadingItems, true);
                return output;
            });
    },

    async getOngoingRequests({ commit, dispatch }, payload) {
        dispatch('clearError');
        commit('setOngoingRequests', []);
        commit('setTotalOngoingRequests', null);
        commit('setHasLoadedOngoingRequests', false);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/rails/ongoing/';

        if (payload && payload.params) {
            // Formulate URL with query string with generateQueryParamsUrl mixin function
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        } else {
            // default parameters
            request_url = request_url + '?page=1&itemsPerPage=-1'
        }

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then(function (response) {
                output = response.data.success;
                if (output) {
                    commit('setOngoingRequests', response.data.data);
                    commit('setTotalOngoingRequests', response.data.total);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setHasLoadedOngoingRequests', true);
                return output;
            });
    },

    async getRequestById({ commit, dispatch }, payload) {
        dispatch('clearError');

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/rails/' + payload;

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then(function (response) {
                output = response.data.success;
                if (output) {
                    commit('setSelectedRequest', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                return output;
            });
    },

    async getEventsFromOperation({ commit }, operationID) {

        if (state.currentPayloadEventOperationId == operationID) {
            return true;
        }

        // clear states
        commit('setOperationEvents', []);
        commit('setHasLoadedOperationEvents', false);

        // populates current payload operation id
        commit('setCurrentPayloadEventOperationId', operationID);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/rails/' + operationID + '/events/';

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then((response) => {
            output = response.data.success;

            if (output) {
                commit('setOperationEvents', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
            return output;
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .finally(function () {
            commit('setHasLoadedOperationEvents', true);
            return output;
        });
    },

    async getOperationsLegacyList({ commit }, payload) {
        commit('setOperationsLegacyList', []);
        commit('setHasLoadedOperationsLegacyList', false);
        commit('setTotalOperationsLegacyList', null);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/rails/listLegacy/';

        // Configure URL parameters
        if (payload && payload.params) {
            // Formulate URL with query string with generateQueryParamsUrl mixin function
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        }

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then((response) => {
            output = response.data.success;

            if (output) {
                commit('setOperationsLegacyList', response.data.data);
                commit('setTotalOperationsLegacyList', response.data.total)
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            commit('setHasLoadedOperationsLegacyList', true);
            return output;
        });
    },

    async deleteRequest({ commit, dispatch }, id) {
        dispatch('clearError');

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/rails/' + id;

        let config = {
            method: 'DELETE',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
        }

        // Execute request & return
        let output = false;

        return axios(config)
            .then(function (response) {
                // 204 response status represents success
                output = response.status == 204;
                if (!output) {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setHasLoaded', true);
                return output;
            });
    },

    async confirmRailOperation({ commit, dispatch }, payload) {
        dispatch('clearError');

        // Handle payload data
        var data = payload.body;

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/rails/' + payload.id + '/confirm/';

        var config = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: data
        }

        // Execute request & return
        let output = false;

        return axios(config)
            .then(function (response) {
                output = response.data.success;
                if (output) {
                    commit('setSelectedRequest', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                return output;
            });
    },

    async editRailOperation({ commit, dispatch }, payload) {
        dispatch('clearError');

        // Handle payload data
        var data = payload.body;

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/rails/' + payload.id;

        var config = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: data
        }

        // Execute request & return
        let output = false;

        return axios(config)
            .then(function (response) {
                output = response.data.success;
                if (output) {
                    commit('setSelectedRequest', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                return output;
            });
    },

    async editDateRailOperation({ commit, dispatch }, payload) {
        dispatch('clearError');

        // Handle payload data
        var data = payload.body;

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/rails/' + payload.id;

        var config = {
            method: 'PATCH',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }

        // Execute request & return
        let output = false;

        return axios(config)
            .then(function (response) {
                output = response.data.success;
                if (output) {
                    commit('setSelectedRequest', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                return output;
            });
    },

    async bankrollReductionCreate({ commit, dispatch }, payload) {
        dispatch('clearError');

        // Handle payload data
        var data = payload;

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/rails/bankrolls/decrement/';
        var config = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: data
        }

        // Execute request & return
        let output = false;

        return axios(config)
            .then(function (response) {
                output = response.data.success;
                if (output) {
                    commit('setSelectedRequest', response.data.data);
                    commit('add', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                return output;
            });
    },

    async bankrollReductionUpdate({ commit, dispatch }, payload) {
        dispatch('clearError');

        // Handle payload data
        var data = payload.body;

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/rails/' + payload.id + '/bankrolls/decrement/';
        var config = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: data
        }

        // Execute request & return
        let output = false;

        return axios(config)
            .then(function (response) {
                output = response.data.success;
                if (output) {
                    commit('setSelectedRequest', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                return output;
            });
    },

    async bankrollIncrementCreate({ commit, dispatch }, payload) {
        dispatch('clearError');

        // Handle payload data
        var data = payload;

        // Configure request_url
        const request_url = Vue.prototype.$url_api + 'v2/rails/bankrolls/increment/';

        // Configure request
        var config = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: data
        }

        // Execute request & return
        let output = false;

        return axios(config)
            .then(function (response) {
                output = response.data.success;
                if (output) {
                    commit('setSelectedRequest', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                return output;
            });
    },

    async bankrollIncrementUpdate({ commit, dispatch }, payload) {
        dispatch('clearError');

        // Handle payload data
        var data = payload.body;

        // Configure Request
        const request_url = Vue.prototype.$url_api + 'v2/rails/' + payload.id + '/bankrolls/increment/';
        var config = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: data
        }

        // Execute request & return
        let output = false;

        return axios(config)
            .then(function (response) {
                output = response.data.success;
                if (output) {
                    commit('setSelectedRequest', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                return output;
            });
    },

    async splitCreate({ commit, dispatch }, payload) {
        dispatch('clearError');


        // Handle payload data
        var data = payload.body;

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/rails/splits/';
        var config = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: data
        }

        // Execute request & return
        let output = false;

        return axios(config)
            .then(function (response) {
                output = response.data.success;
                if (output) {
                    commit('setSelectedRequest', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                return output;
            });
    },

    async splitUpdate({ commit, dispatch }, payload) {
        dispatch('clearError');

        // Handle payload data
        var data = payload.body;

        // Configure request_url
        const request_url = Vue.prototype.$url_api + 'v2/rails/' + payload.id + '/splits/';

        // Configure request
        var config = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: data
        };

        // Execute request & return
        let output = false;

        return axios(config)
            .then(function (response) {
                output = response.data.success;
                if (output) {
                    commit('setSelectedRequest', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                return output;
            });
    },

    async deposit({ dispatch, commit }, payload) {
        dispatch('clearError');

        // Handle payload data
        var data = payload.body;

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/rails/deposits/';

        var config = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: data
        }

        // Execute request & return
        let output = false;

        return axios(config)
            .then(function (response) {
                output = response.data.success;
                if (output) {
                    commit('setSelectedRequest', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                return output;
            });
    },

    async withdraw({ dispatch, commit }, payload) {
        dispatch('clearError');

        // Handle payload data
        var data = payload.body;

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/rails/withdraws/';

        var config = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: data
        }

        // Execute request & return
        let output = false;

        return axios(config)
            .then(function (response) {
                output = response.data.success;
                if (output) {
                    commit('setSelectedRequest', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                return output;
            });
    },

    async rejectRequest({ commit, dispatch }, payload) {
        dispatch('clearError');

        // Handle payload data
        var data = payload.body;

        // Configure request_url
        const request_url = Vue.prototype.$url_api + 'v2/rails/' + payload.id + '/reject/';

        let config = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: data
        }

        // Execute request & return
        let output = false;

        return axios(config)
            .then(function (response) {
                output = response.data.success;
                if (output) {
                    commit('setSelectedRequest', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setHasLoaded', true);
                return output;
            });
    },

    async swapCreate({ commit, dispatch }, payload) {
        //clear state
        dispatch('clearError');

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/rails/swaps/';
        var config = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: payload
        }
        // Execute request & return
        let output = false;

        return axios(config)
            .then(function (response) {
                output = response.data.success;
                if (output) {
                    commit('setSelectedRequest', response.data.data);
                    commit('add', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                return output;
            });
    },

    async operationCreate({ dispatch, commit }, payload) {
        dispatch('clearError');

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/rails/';

        var config = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: payload
        }

        // Execute request & return
        let output = false;

        return axios(config)
            .then(function (response) {
                output = response.data.success;
                if (output) {
                    commit('setSelectedRequest', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                return output;
            });
    },

    async rakeCreate({ commit, dispatch }, payload) {
        //clear state
        dispatch('clearError');

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/rails/makeups';
        var config = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            data: payload
        }
        // Execute request & return
        let output = false;

        return axios(config)
            .then(function (response) {
                output = response.data.success;
                if (output) {
                    commit('setSelectedRequest', response.data.data);
                    commit('add', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                return output;
            });
    },

    reset({ commit }) {
        commit('setRequests', []);
        commit('setOngoingRequests', []);
        commit('setFinalizedRequests', []);
        commit('setExportedRequests', []);
        commit('setOperationEvents', []);
        commit('setSelectedRequest', null);
        commit('setHasLoaded', false);
        commit('setHasLoadedOngoingRequests', false);
        commit('setHasLoadedFinalizedRequests', false);
        commit('setHasLoadedOperationEvents', false);
        commit('setTotalOngoingRequests', 0);
        commit('setTotalFinalizedRequests', 0);
        commit('setTotalRequests', 0);
        commit('setError', null);
        commit('setHumanError', null);
    },

    clearError({ commit }) {
        commit('setError', null);
        commit('setHumanError', null);
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};