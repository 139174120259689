import Vue from "vue";
import axios from "axios";
import store from '@/store/v2/notifications';
import VueCookies from "vue-cookies";

// Cria uma instância do Axios
const api = axios.create({
  baseURL: Vue.prototype.$url_api,
  timeout: 120000,
  params: {}
});

api.interceptors.request.use(
  (config) => {
    // Adiciona o token de autenticação no cabeçalho da requisição
    const token = VueCookies.get(Vue.prototype.$cookie_names.jwt_token);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    if (Vue.prototype.$x_api_key) {
      config.headers.common['x-api-key'] = Vue.prototype.$x_api_key;
    }
    

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Adiciona o interceptor de resposta
api.interceptors.response.use(
  (response) => response, // Retorna a resposta normal caso não haja erro
  (error) => {
    if (error.response) {
      const { data, status } = error.response;

      if (data && data.message === "User has unread alerts." && status === 403) {
        store.state.openDialogNotificationPlayer = true;
        return Promise.reject({
          ...error,
          customMessage: "Você possui alertas não lidos! Verifique a aba de notificações.",
        });
      }
    }
    return Promise.reject(error);
  }
);

export default api;