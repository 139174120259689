import { render, staticRenderFns } from "./TableGamesPlayed.vue?vue&type=template&id=36866086&scoped=true"
import script from "./TableGamesPlayed.js?vue&type=script&lang=js&external"
export * from "./TableGamesPlayed.js?vue&type=script&lang=js&external"
import style0 from "./TableGamesPlayed.vue?vue&type=style&index=0&id=36866086&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "36866086",
  null
  
)

export default component.exports