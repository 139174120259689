import { render, staticRenderFns } from "./Factors-All.vue?vue&type=template&id=1b84098e&scoped=true"
import script from "./Factors-All.js?vue&type=script&lang=js&external"
export * from "./Factors-All.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b84098e",
  null
  
)

export default component.exports