import { render, staticRenderFns } from "./LiveEvents.vue?vue&type=template&id=1a5b256a&scoped=true"
import script from "./LiveEvents.js?vue&type=script&lang=js&external"
export * from "./LiveEvents.js?vue&type=script&lang=js&external"
import style0 from "./LiveEvents.vue?vue&type=style&index=0&id=1a5b256a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a5b256a",
  null
  
)

export default component.exports