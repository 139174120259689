import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';

export default {
  mixins: [
    AuthMixin,
  ],

  data() {
      return {
        dialogVisible: this.openDialog,
        confirmNotification: false,
        isRead: false,
      };
    },
  
  computed: {
    ...mapGetters({
      selectedNotification: 'notifications/selectedNotification',
      loading: 'notifications/loading',
      error: 'notifications/error',
      humanError: 'notifications/humanError',
    }),

    formattedNotification() {
      // endpoint alerts/me
      if (this.selectedNotification && this.selectedNotification.is_open && Array.isArray(this.selectedNotification.alert)) {
        const firstAlert = this.selectedNotification.alert[0] || {};
        return {
          id: firstAlert.id || null,
          title: firstAlert.title || "No Title",
          description: firstAlert.description || "No Description",
          created_at: firstAlert.created_at || "No Date",
        };
      }

      // endpoint alerts
      if (this.selectedNotification && this.selectedNotification.id && Array.isArray(this.selectedNotification.players)) {
        return {
          id: this.selectedNotification.id,
          title: this.selectedNotification.title || "Sem Título",
          description: this.selectedNotification.description || "Sem Mensagem",
          created_at: this.selectedNotification.created_at || "Sem data",
        };
      }

      return {
        id: null,
        title: "Sem Título",
        description: "Sem Mensagem",
        created_at: "Sem data",
        created_by: null,
        players: []
      };
    }
  },
  
  props: {
    openDialog: {
      type: Boolean,
      required: true
    },
    notification: {
      type: Object,
      required: false
    }
  },
  watch: {
    openDialog(value) {
      this.dialogVisible = value;
      this.confirmNotification = false;
    }
  },
  methods: {
    closeDialog() {
      this.isRead = false;
      this.$emit('close-dialog');
    }
  }
};