import Vue from 'vue';
import Vuex from 'vuex';
import axios from '@/plugins/axios';
import StoreMixin from '@/mixins/Store.vue';
import DataMixin from '@/mixins/Data.vue';

Vue.use(Vuex);
Vue.use(StoreMixin);
Vue.use(DataMixin);

const state = {
    groups: [],
    groupsChanges: [],
    pendingGroupsChanges: [],
    finalizedGroupsChanges: [],
    groupsChangesTotal: null,
    pendingGroupsChangesTotal: null,
    finalizedGroupsChangesTotal: null,
    groupsTotal: null,
    groupGameEntries: null,
    groupPnl: null,
    groupPnlGraph: null,
    selectedGroup: null,
    selectedGroupChange: null,
    selectedGroupMember: null,
    groupChangesByUserId: null,
    error: null,
    errorStatus: null,
    humanError: null,
    loading: true,
    groupGameEntriesLoading: true,
    groupPnlLoading: true,
    groupPnlGraphLoading: true,
    selectedGroupLoading: true,
    groupsChangesLoading: true,
    pendingGroupsChangesLoading: true,
    finalizedGroupsChangesLoading: true,
    groupChangesByUserIdLoading: true,
    groupChangesByUserIdError: null,
    groupChangesByUserIdHumanError: null,
    lastPayloadGroups: {},
    lastPayloadGroupChangesByUserId: {},
};

const getters = {
    groups: state => state.groups,
    groupsChanges: state => state.groupsChanges,
    pendingGroupsChanges: state => state.pendingGroupsChanges,
    finalizedGroupsChanges: state => state.finalizedGroupsChanges,
    groupChangesByUserId: state => state.groupChangesByUserId,
    groupsTotal: state => state.groupsTotal,
    groupsChangesTotal: state => state.groupsChangesTotal,
    pendingGroupsChangesTotal: state => state.pendingGroupsChangesTotal,
    finalizedGroupsChangesTotal: state => state.finalizedGroupsChangesTotal,
    groupGameEntries: state => state.groupGameEntries,
    groupPnl: state => state.groupPnl,
    groupPnlGraph: state => state.groupPnlGraph,
    selectedGroup: state => state.selectedGroup,
    selectedGroupMember: state => state.selectedGroupMember,
    selectedGroupChange: state => state.selectedGroupChange,
    error: state => state.error,
    errorStatus: (state) => state.errorStatus,
    humanError: state => state.humanError,
    loading: state => state.loading,
    groupMembersLoading: state => state.groupMembersLoading,
    groupMembersTotalLoading: state => state.groupMembersTotalLoading,
    groupGameEntriesLoading: state => state.groupGameEntriesLoading,
    groupPnlLoading: state => state.groupPnlLoading,
    groupPnlGraphLoading: state => state.groupPnlGraphLoading,
    selectedGroupLoading: state => state.selectedGroupLoading,
    groupsChangesLoading: state => state.groupsChangesLoading,
    pendingGroupsChangesLoading: state => state.pendingGroupsChangesLoading,
    finalizedGroupsChangesLoading: state => state.finalizedGroupsChangesLoading,
    groupChangesByUserIdLoading: state => state.groupChangesByUserIdLoading,
    groupChangesByUserIdError: state => state.groupChangesByUserIdError,
    groupChangesByUserIdHumanError: state => state.groupChangesByUserIdHumanError
};

const mutations = {
    setGroups: (state, payload) => { state.groups = payload; },
    setGroupsTotal: (state, payload) => { state.groupsTotal = payload; },
    setGroupGameEntries: (state, payload) => { state.groupGameEntries = payload; },
    setGroupPnl: (state, payload) => { state.groupPnl = payload; },
    setGroupPnlGraph: (state, payload) => { state.groupPnlGraph = payload; },
    setSelectedGroup: (state, payload) => { state.selectedGroup = payload; },
    setSelectedGroupMember: (state, payload) => { state.selectedGroupMember = payload },
    setSelectedGroupChange: (state, payload) => { state.selectedGroupChange = payload },
    setError: (state, payload) => { state.error = payload; },
    setErrorStatus: (state, payload) => { state.errorStatus = payload },
    setHumanError: (state, payload) => { state.humanError = payload; },
    setLoading: (state, payload) => { state.loading = payload },
    setGroupGameEntriesLoading: (state, payload) => { state.groupGameEntriesLoading = payload },
    setGroupPnlLoading: (state, payload) => { state.groupPnlLoading = payload },
    setGroupPnlGraphLoading: (state, payload) => { state.groupPnlGraphLoading = payload },
    setSelectedGroupLoading: (state, payload) => { state.selectedGroupLoading = payload },
    addGroup: (state, payload) => {
        if (! state.groups || ! state.groups.length)
        state.groups = [];
    
        state.groups.unshift(payload);
    },
    updateGroup: (state, payload) => {
        const index = state.groups.findIndex(d => d.id == payload.id);

        const updatedItems = [
            ...state.groups.slice(0, index),
            payload,
            ...state.groups.slice(index + 1)
        ];

        state.groups = updatedItems;
    },

    setGroupsChanges: (state, payload) => {
        state.groupsChanges = payload;
    },
    setGroupsChangesTotal: (state, payload) => {
        state.groupsChangesTotal = payload;
    },
    setGroupsChangesLoading: (state, payload) => {
        state.groupsChangesLoading = payload;
    },

    setPendingGroupsChanges: (state, payload) => {
        state.pendingGroupsChanges = payload;
    },
    setPendingGroupsChangesTotal: (state, payload) => {
        state.pendingGroupsChangesTotal = payload;
    },
    setPendingGroupsChangesLoading: (state, payload) => {
        state.pendingGroupsChangesLoading = payload;
    },

    setFinalizedGroupsChanges: (state, payload) => {
        state.finalizedGroupsChanges = payload;
    },
    setFinalizedGroupsChangesTotal: (state, payload) => {
        state.finalizedGroupsChangesTotal = payload;
    },
    setFinalizedGroupsChangesLoading: (state, payload) => {
        state.finalizedGroupsChangesLoading = payload;
    },

    setGroupChangesByUserId: (state, payload) => {
        state.groupChangesByUserId = payload;
    },
    
    setGroupChangesByUserIdLoading: (state, payload) => {
        state.groupChangesByUserIdLoading = payload;
    },

    setLastPayloadGroups: (state, payload) => {
        state.lastPayloadGroups = payload;
    },

    setGroupChangesByUserIdError: (state, payload) => {
        state.groupChangesByUserIdError = payload;
    },

    setGroupChangesByUserIdHumanError: (state, payload) => {
        state.groupChangesByUserIdHumanError = payload;
    },

    setLastPayloadGroupChangesByUserId: (state, payload) => {
        state.lastPayloadGroupChangesByUserId = payload;
    },

};

const actions = {
    async get({ commit, dispatch, state }, payload) {

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/groups/';

        // Configure URL Parameters
        let parameters = payload && payload.params
            ? payload.params
            : StoreMixin.methods.getParametersDefault();
        
        // Build URL with parameters

        let areObjectsEqual = DataMixin.methods.areObjectsEqual(state.lastPayloadGroups, parameters);
            
        if (areObjectsEqual) return false;
        
        commit('setLastPayloadGroups', parameters);
        
        // reset groups
        dispatch('reset');
        
        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, parameters);

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setGroups', response.data.data);
                commit('setGroupsTotal', response.data.total);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            commit('setLoading', false);
            return output;
        });
    },

    // List all groups changes items
    async getGroupsChanges({ commit, dispatch }, payload) {
        // declare variables
        let setItems;
        let setTotalItems;
        let setLoadingItems;
        let handleMutation = StoreMixin.methods.handleGroupsChangesMutations();

        if (payload && payload.params) {
            handleMutation = StoreMixin.methods.handleGroupsChangesMutations(payload.params['status[' + 0 + ']']);
        }
    
        // mutations that will be used to populate states based on status parameter
        setItems = handleMutation.items;
        setTotalItems = handleMutation.total;
        setLoadingItems = handleMutation.loading;
        
        // clear states
        dispatch('clearError');
        commit(setItems, []);
        commit(setTotalItems, 0);
        commit(setLoadingItems, true);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/groups/changes/';

        // Configure URL Parameters
        let parameters = payload && payload.params
            ? payload.params
            : StoreMixin.methods.getParametersDefault();
        
        // Build URL with parameters
        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, parameters);

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;
            if (output) {
                commit(setItems, response.data.data);
                commit(setTotalItems, response.data.total);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit(setItems, []);
            commit(setTotalItems, 0);
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            commit(setLoadingItems, false);
            return output;
        });
    },

    // List a specific groups change item
    async getSingleGroupChange({ commit, dispatch }, payload) {

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/groups/changes/' + payload.id;

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setSelectedGroupChange', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            // commit('loadings',false);
            return output;
        });
    },

    // List a specific group changes by user Id
    async getGroupChangesByUserId({ commit, state }, payload) {

        // validate payload objects before API Call
        let areObjectsEqual = DataMixin.methods.areObjectsEqual(state.lastPayloadGroupChangesByUserId, payload);
        
        // save last payload
        commit('setLastPayloadGroupChangesByUserId', payload);

        if (areObjectsEqual) return false;

        // clear states
        commit('setGroupChangesByUserId', null);
        commit('setGroupChangesByUserIdLoading', true);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/groups/changes/user/' + payload.id;

        // Configure URL Parameters
        let parameters = payload && payload.params
        ? payload.params
        : null;
            
        // Build URL with parameters
        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, parameters);

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;
            if (output) {
                commit('setGroupChangesByUserId', response.data.data);
            } else {
                commit('setGroupChangesByUserIdError', response.data.message);
                commit('setGroupChangesByUserIdHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setGroupChangesByUserIdError', error.response ? error.response.data.message : error);
            commit('setGroupChangesByUserIdHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            commit('setGroupChangesByUserIdLoading', false);
            return output;
        });
    },

    // List a specific group changes by user logged in
    async getOwnGroupChanges({ commit, dispatch }, payload) {

       commit('setGroupChangesByUserIdLoading', true);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/groups/changes/user/me';

        // Configure URL Parameters
        let parameters = payload && payload.params
            ? payload.params
            : null;
        
        // Build URL with parameters
        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, parameters);

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setGroupChangesByUserId', response.data.data);
            } else {
                commit('setGroupChangesByUserId', null);
                commit('setGroupChangesByUserIdError', response.data.message);
                commit('setGroupChangesByUserIdHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setGroupChangesByUserId', null);
            commit('setGroupChangesByUserIdError', error.response ? error.response.data.message : error);
            commit('setGroupChangesByUserIdHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            commit('setGroupChangesByUserIdLoading', false);
            return output;
        });
    },

    async createLevelChange ({ commit, dispatch }, payload) {
        // clear state
        dispatch('clearError');

        // Handle payload data
        let data = JSON.stringify(payload);

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/groups/changes';
        let config = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }

        // Execute request & return
        let output = false;

        return axios(config)
        .then(function (response) {
            output = response.data.success;
            if (output) {
                commit('addGroup', response.data.data);
                commit('setSelectedGroup', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            return output;
        });
    },

    // List a specific groups change item
    async approveSingleGroupChange({ commit, dispatch }, payload) {

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/groups/changes/' + payload.id + '/approve/';

        // Execute request & return
        let output = false;

        return axios.patch(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setSelectedGroupChange', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            // commit('loadings',false);
            return output;
        });
    },

    async rejectSingleGroupChange({ commit, dispatch }, payload) {

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/groups/changes/' + payload.id + '/reject/';

        // Execute request & return
        let output = false;

        return axios.patch(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setSelectedGroupChange', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            // commit('loadings',false);
            return output;
        });
    },

    async restoreSingleGroupChange({ commit, dispatch }, payload) {

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/groups/changes/' + payload.id + '/restore/';

        // Execute request & return
        let output = false;

        return axios.patch(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setSelectedGroupChange', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            // commit('loadings',false);
            return output;
        });
    },

    async getGroupGameEntries({ commit, dispatch }, payload) {
        // clear state
        dispatch('clearError');
        commit('setGroupGameEntries', null);
        commit('setGroupGameEntriesLoading', true);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/groups/' + payload.id + '/gameEntries/count';

        // Configure URL parameters
        if (payload && payload.params) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        }

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setGroupGameEntries', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            commit('setGroupGameEntriesLoading', false);
            return output;
        });
    }, 

    // Read Group PNL - Game Entries
    async getGroupPnl({ commit, dispatch }, payload) {
        // clear state
        dispatch('clearError');
        commit('setGroupPnl', null);
        commit('setGroupPnlLoading', true);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/groups/' + payload.id + '/gameEntries/pnl/';

        // Configure URL parameters
        if (payload && payload.params) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        }

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setGroupPnl', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            commit('setGroupPnlLoading', false);
            return output;
        });
    },

    // Read Group PNL Graph - Game Entries
    async getGroupPnlGraph({ commit, dispatch }, payload) {
        // clear state
        dispatch('clearError');
        commit('setGroupPnlGraph', null);
        commit('setGroupPnlGraphLoading', true);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/groups/' + payload.id + '/gameEntries/pnl/graph';

        // Configure URL parameters
        if (payload && payload.params) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        }

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setGroupPnlGraph', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            commit('setGroupPnlGraphLoading', false);
            return output;
        });
    },

    async getGroup({ commit, dispatch }, payload) {
        // clear state
        dispatch('clearError');
        commit('setSelectedGroup', null);
        commit('setSelectedGroupLoading', true);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/groups/' + payload.id;

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setSelectedGroup', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
                commit('setErrorStatus', response.status);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            commit('setErrorStatus', error.response.status);
            output = false;
        })
        .then(function () {
            commit('setSelectedGroupLoading', false);
            return output;
        });
    },

    async create({ commit, dispatch }, payload) {
        // clear state
        dispatch('clearError');

        // Handle payload data
        let data = JSON.stringify(payload);

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/groups/';
        let config = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }

        // Execute request & return
        let output = false;

        return axios(config)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('addGroup', response.data.data);
                commit('setSelectedGroup', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            return output;
        });
    },

    async update({ commit, dispatch }, payload) {
        // clear state
        dispatch('clearError');

        // Handle payload data
        let data = JSON.stringify(payload.body);

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/groups/' + payload.id;
        let config = {
            method: 'PUT',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }

        // Execute request & return
        let output = false;

        return axios(config)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('updateGroup', response.data.data);
                commit('setSelectedGroup', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            return output;
        });
    },

    async remove({ commit, dispatch }, payload) {
        // clear state
        dispatch('clearError');

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/groups/' + payload.id;
        let config = {
            method: 'DELETE',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
        }

        // Execute request & return
        let output = false;

        return axios(config)
        .then(function (response) {
            // 204 response status represents success
            output = response.status == 204;
            if (output) {
                commit('setLastPayloadGroups', {});
            }else{
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            return output;
        });
    },

    /**
     * Reset to initial state
     */
    reset({commit}) {
        commit('setGroups', []);
        commit('setError', null);
        commit('setHumanError', null);
        commit('setLoading', true);
        commit('setErrorStatus', null);
    },

    clearError({ commit }) {
        commit('setError', null);
        commit('setHumanError', null);
        commit('setErrorStatus', null);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
