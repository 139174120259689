import { render, staticRenderFns } from "./LiveTeams.vue?vue&type=template&id=c7248f78&scoped=true"
import script from "./LiveTeams.js?vue&type=script&lang=js&external"
export * from "./LiveTeams.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c7248f78",
  null
  
)

export default component.exports