import axios from '@/plugins/axios';
import Vue from 'vue';
import Vuex from 'vuex';
import StoreMixin from '@/mixins/Store.vue';

Vue.use(Vuex);
Vue.use(StoreMixin);

const state = {
    wallets: [],
    walletsAll: [],
    walletsManagement: [],
    walletsByUserID: [],
    selectWallet: null,
    hasLoaded: false,
    hasLoadedWalletsAll: false,
    hasLoadedWalletsManagement: false,
    hasLoadedWalletsByUserID: false,
    error: null,
    humanError: null,
};

const getters = {
    wallets: (state) => state.wallets,
    walletsAll: (state) => state.walletsAll,
    walletsManagement: (state) => state.walletsManagement,
    walletsByUserID: (state) => state.walletsByUserID,
    selectedWallet: (state) => state.selectWallet,
    hasLoaded: (state) => state.hasLoaded,
    hasLoadedWalletsAll: (state) => state.hasLoadedWalletsAll,
    hasLoadedWalletsManagement: (state) => state.hasLoadedWalletsManagement,
    hasLoadedWalletsByUserID: (state) => state.hasLoadedWalletsByUserID,
    error: (state) => state.error,
    humanError: (state) => state.humanError,
};

const mutations = {
    setWallets(state, payload) {
        state.wallets = payload;
    },

    setWalletsAll(state, payload) {
        state.walletsAll = payload
    },

    setWalletsManagement(state, payload) {
        state.walletsManagement = payload
    },
    
    setWalletsByUserID(state, payload) {
        state.walletsByUserID = payload;
    },

    setSelectedWallet(state, payload) {
        state.selectedWallet = payload;
    },

    setHasLoaded(state, payload) {
        state.hasLoaded = payload;
    },

    setHasLoadedWalletsAll(state, payload) {
        state.hasLoadedWalletsAll = payload;
    },

    setHasLoadedWalletsManagement(state, payload) {
        state.hasLoadedWalletsManagement = payload;
    },

    setHasLoadedWalletsByUserID(state, payload) {
        state.hasLoadedWalletsByUserID = payload;
    },

    setError(state, payload) {
        state.error = payload;
    },

    setHumanError(state, payload) {
        state.humanError = payload;
    }
}

const actions = {
    async getWallets({ commit, dispatch }, payload) {
        commit('setHasLoaded', false);
        dispatch('clearError');

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/nicknames?type=2';

        // Configure URL Parameters
        let parameters = payload && payload.params
        ? payload.params
        : StoreMixin.methods.getParametersDefault();
    
        // Build URL with parameters
        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, parameters);
        
        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setWallets', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            commit('setHasLoaded', true);
            return output;
        });
    },

    async getWalletsAll({ commit, dispatch }, userID = null) {
        commit('setHasLoadedWalletsAll', false);
        dispatch('clearError');

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/nicknames/active?type=2';
        
        // Configure URL Parameters
        let parameters = StoreMixin.methods.getParametersDefault();
        
        // Build URL with parameters
        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, parameters);
        
        // Add userID parameter to request_URL
        if (userID) {
            request_url = request_url + '&user=' + userID;
        }

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setWalletsAll', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            commit('setHasLoadedWalletsAll', true);
            return output;
        });
    },
    // Get wallets for user (role manager or admin) can access with amount values
    async getWalletsFromManagementWithAmounts({ commit, dispatch }) {
        commit('setHasLoadedWalletsManagement', false);
        dispatch('clearError');

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/nicknames/active/?management=1&page=1&itemsPerPage=-1';

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setWalletsManagement', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            commit('setHasLoadedWalletsManagement', true);
            return output;
        });
    },

    // Get wallets for user (role player) can access without amount values
    async getWalletsFromManagement({ commit, dispatch }) {
        commit('setHasLoadedWalletsManagement', false);
        dispatch('clearError');

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/nicknames/management/?page=1&itemsPerPage=-1';

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setWalletsManagement', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            commit('setHasLoadedWalletsManagement', true);
            return output;
        });
    },

    reset({commit}) {
        commit('setWallets', []);
        commit('setWalletsManagement', []);
        commit('setWalletsByUserID', []);
        commit('setSelectedWallet', null);
        commit('setHasLoaded', false);
        commit('setHasLoadedWalletsManagement', false);
        commit('setHasLoadedWalletsByUserID', false);
        commit('setError', null);
        commit('setHumanError', null);
    },
    
    clearError({commit}) {
        commit('setError', null);
        commit('setHumanError', null);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
