import Vue from 'vue';
import Vuex from 'vuex';
import axios from '@/plugins/axios';

Vue.use(Vuex);

const state = {
    networks: null
};

const getters = {
    networks: state => state.networks
};

const mutations = {
    setNetworks: (state, payload) => { state.networks = payload; },
};

const actions = {
    async get({ commit }, payload) {
        await axios
        .get(Vue.prototype.$url_api + 'v1/networks.php')
        .then(result => {
            commit('setNetworks', result.data.data);
        })
        .catch(err => { console.log(err); });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};