import { render, staticRenderFns } from "./TableRequests.vue?vue&type=template&id=39df823a&scoped=true"
import script from "./TableRequests.js?vue&type=script&lang=js&external"
export * from "./TableRequests.js?vue&type=script&lang=js&external"
import style0 from "./TableRequests.vue?vue&type=style&index=0&id=39df823a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39df823a",
  null
  
)

export default component.exports