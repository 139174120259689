import Vue from 'vue';
import Vuex from 'vuex';
import axios from '@/plugins/axios';

Vue.use(Vuex);

const state = {
    teams: null
};

const getters = {
    teams: state => state.teams
};

const mutations = {
    setTeams: (state, payload) => { state.teams = payload; },
};

const actions = {
    async getTeams({ commit }, payload) {
        await axios
        .get(Vue.prototype.$url_api + 'v1/teams.php')
        .then(result => {
            commit('setTeams', result.data.data);
        })
        .catch(err => { console.log(err); });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
