import { render, staticRenderFns } from "./CardTransferToPlayer.vue?vue&type=template&id=dd8f6338"
import script from "./CardTransferToPlayer.js?vue&type=script&lang=js&external"
export * from "./CardTransferToPlayer.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports