import Vue from 'vue';
import Vuex from 'vuex';
import axios from '@/plugins/axios';

Vue.use(Vuex);

const state = {
    applications: [],
    selectedApplication: null,
    selectedApplicationPhase1: null,
    selectedApplicationPhase2: null,
    selectedApplicationPhase3: null,
    rejectionReasons: [],
    pastApplications: [],
    idApplicationInView: null,
    statistics: {}
};

const getters = {
    applications: state => state.applications,
    selectedApplication: state => state.selectedApplication,
    selectedApplicationPhase1: state => state.selectedApplicationPhase1,
    selectedApplicationPhase2: state => state.selectedApplicationPhase2,
    selectedApplicationPhase3: state => state.selectedApplicationPhase3,
    rejectionReasons: state => state.rejectionReasons,
    pastApplications: state => state.pastApplications,
    idApplicationInView: state => state.idApplicationInView,
    statistics: state => state.statistics
};

const mutations = {
    /**
     * Convert fields after CF7 update
     * @ref https://gitlab.com/polarize-poker-it-division/playertracker/-/issues/111
     */
    setSelectedApplication: (state, payload) => {
        if(payload != {}) {
            let radioFields = {
                data_stage_1_applications: ['player-country', 'player-education', 'player-limits', 'player-hud', 'player-numofgames', 'player-find'],
                data_stage_2_applications: [],
                data_stage_3_applications: ['interview-medium']
            };

            for (const stage in radioFields) {
                for (const field of radioFields[stage]) {
                    if (payload[stage] !== null && payload[stage][field] !== undefined && Array.isArray(payload[stage][field]) && payload[stage][field][0]) {
                        payload[stage][field] = payload[stage][field][0];
                    }
                }
            }
        }

        // Set
        state.selectedApplication = payload;
    },

    setApplications: (state, payload) => {
        state.applications = payload;
    },

    updateApplication: (state, payload) => {
        const application = state.applications.filter(
            i => i.id_applications == payload.id_applications
        );
        application[0].stage_applications += 1;

        const index = state.applications.findIndex(
            i => i.id_applications == payload.id_applications
        );

        const updatedItems = [
            ...state.applications.slice(0, index),
            application[0],
            ...state.applications.slice(index + 1)
        ];

        state.applications = updatedItems;
    },

    setPastApplications: (state, payload) => { state.pastApplications = payload; },
    setIdApplicationInView: (state, payload) => { state.idApplicationInView = payload; },
    setStatistics: (state, payload) => { state.statistics = payload; },
    setRejectionReasons: (state, payload) => { state.rejectionReasons = payload; }
};

const actions = {
    async getApplications({ commit }) {
        await axios
        .get(Vue.prototype.$url_api + 'v1/applications.php')
        .then(result => {
            commit('setApplications', result.data.data);
            //console.log(result.data.data);
        })
        .catch(err => {
            console.log(err);
        });
    },

    async getApplicationById({ commit }, payload) {
        await axios
        .get(Vue.prototype.$url_api + 'v1/applications.php?id=' + payload.id)
        .then(result => {
            commit('setSelectedApplication', result.data.data);
        })
        .catch(err => {
            console.log(err);
        });
    },

    async nextPhase({ commit }, payload) {
        //console.log({ payload });

        let requestUrl = payload.url + 'v1/applications.php?id=' + payload.id;

        await axios
        .put(requestUrl, requestParameters)
        .then(result => {
            commit('updateApplication', result.data.data);
        })
        .catch(err => {
            console.log(err);
        });
    },

    async approve({ commit }, payload) {
        let requestUrl = Vue.prototype.$url_api + 'v1/applications.php?id=' + state.selectedApplication.id_applications;
        let requestParameters = new URLSearchParams();
        requestParameters.append('action', 'next_stage');
        requestParameters.append('evaluation', payload.evaluation);
        requestParameters.append('notes', payload.notes);
        requestParameters.append('group', payload.group);
        requestParameters.append('team', payload.team);
        requestParameters.append('coach', payload.coach);

        await axios
        .put(requestUrl, requestParameters)
        .then(result => {
            commit('updateApplication', result.data.data);
        })
        .catch(err => {
            console.log(err);
        });
    },

    async reject({ commit }, payload) {
        let requestUrl = Vue.prototype.$url_api + 'v1/applications.php?id=' + state.selectedApplication.id_applications;
        let requestParameters = new URLSearchParams();
        requestParameters.append('action', 'reject');
        requestParameters.append('evaluation', payload.evaluation);
        requestParameters.append('notes', payload.notes);
        requestParameters.append('reason', payload.reason);

        /*console.log('==================================0');
        console.log('payload:');
        console.log(payload);
        console.log('==================================0');*/

        await axios
        .put(requestUrl, requestParameters)
        .then(result => {
            //console.log(result);
            //commit('updateApplication', result.data.data);
        })
        .catch(err => { console.log(err); });
    },

    async getPastApplications({commit}) {
        if ( state.selectedApplication.candidate_email_applications === null )
            return false;
        
        await axios
        .get(Vue.prototype.$url_api + 'v1/applications.php?email=' + state.selectedApplication.candidate_email_applications)
        .then(result => {
            //console.log(result);
            commit('setPastApplications', result.data.data);
        })
        .catch(err => { console.log(err); });
    },

    async getStatistics({commit}) {
        await axios
        .get(Vue.prototype.$url_api + 'v1/applications.php?statistics')
        .then(result => {
            //console.log(result);
            commit('setStatistics', result.data.data);
        })
        .catch(err => { console.log(err); });
    },

    async getRejectionReasons({commit}) {
        await axios
        .get(Vue.prototype.$url_api + 'v1/applications_rejection_reasons.php')
        .then(result => {
            //console.log(result);
            commit('setRejectionReasons', result.data.data);
        })
        .catch(err => { console.log(err); });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
