import Vue from 'vue';
import Vuex from 'vuex';
import axios from '@/plugins/axios';
import StoreMixin from '@/mixins/Store.vue';
import DataMixin from '@/mixins/Data.vue';

Vue.use(Vuex);
Vue.use(StoreMixin);
Vue.use(DataMixin);

const state = {
    notifications: [],

    myNotifications: [],
    myNotificationsTotal: null,
    myNotificationsLoading: false,

    error: null,
    humanError: null,
    loading: true,
    notificationsTotal: null,
    selectedNotification: null,
    selectedNotificationLoading: null,
    notificationsDrawer: [],
    notificationsDrawerTotal: null,
    loadingNotificationsDrawer: false,
    lastPayloadNotifications: {},
    myNotificationsDrawer: [],
    myNotificationsDrawerTotal: null,
    myNotificationsDrawerLoading: false,
    openDialogNotificationPlayer: false,
};

const getters = {
    notifications: state => state.notifications,
    myNotifications: state => state.myNotifications,
    myNotificationsTotal: state => state.myNotificationsTotal,
    myNotificationsLoading: state => state.myNotificationsLoading,
    error: state => state.error,
    humanError: state => state.humanError,
    loading: state => state.loading,
    notificationsTotal: state => state.notificationsTotal,
    selectedNotification: state => state.selectedNotification,
    notificationsDrawer: state => state.notificationsDrawer,
    notificationsDrawerTotal: state => state.notificationsDrawerTotal,
    loadingNotificationsDrawer: state => state.loadingNotificationsDrawer,
    lastPayloadNotifications: state => state.lastPayloadNotifications,
    myNotificationsDrawer: state => state.myNotificationsDrawer,
    myNotificationsDrawerTotal: state => state.myNotificationsDrawerTotal,
    myNotificationsDrawerLoading: state => state.myNotificationsDrawerLoading,
    openDialogNotificationPlayer: state => state.openDialogNotificationPlayer,
};

const mutations = {
    setNotifications: (state, payload) => { state.notifications = payload; },
    setError: (state, payload) => { state.error = payload; },
    setErrorStatus: (state, payload) => { state.errorStatus = payload },
    setHumanError: (state, payload) => { state.humanError = payload; },
    setLoading: (state, payload) => { state.loading = payload },
    setNotificationsTotal: (state, payload) => { state.notificationsTotal = payload; },
    setSelectedNotification: (state, payload) => { state.selectedNotification = payload; },
    setSelectedNotificationLoading: (state, payload) => { state.selectedNotificationLoading = payload },
    setNotificationsDrawer: (state, payload) => { state.notificationsDrawer = payload; },
    setLoadingNotificationsDrawer: (state, payload) => { state.loadingNotificationsDrawer = payload; },
    setNotificationsDrawerTotal: (state, payload) => { state.notificationsDrawerTotal = payload; },
    addNotifications: (state, payload) => {
        if (!state.notifications || !state.notifications.length)
            state.notifications = [];

        state.notifications.unshift(payload);
    },
    setLastPayloadNotifications: (state, payload) => { state.lastPayloadNotifications = payload; },
    setMyNotificationsDrawer: (state, payload) => { state.myNotificationsDrawer = payload; },
    setMyNotificationsDrawerTotal: (state, payload) => { state.myNotificationsDrawerTotal = payload; },
    setMyNotificationsDrawerLoading: (state, payload) => { state.myNotificationsDrawerLoading = payload; },
    setMyNotifications: (state, payload) => { state.myNotifications = payload; },
    setMyNotificationsTotal: (state, payload) => { state.myNotificationsTotal = payload; },
    setMyNotificationsLoading: (state, payload) => { state.myNotificationsLoading = payload; },
    setOpenDialogNotificationPlayer: (state, payload) => { state.openDialogNotificationPlayer = payload; },
};

const actions = {

    clearError({ commit }) {
        commit('setError', null);
        commit('setHumanError', null);
        commit('setErrorStatus', null);
    },

    async getNotifications({ dispatch, commit }, payload) {
    
      let areObjectsEqual = DataMixin.methods.areObjectsEqual(state.lastPayloadNotifications, payload);
      if (areObjectsEqual) return false;
      // save last payload
      commit('setLastPayloadNotifications', payload);
      // clear state
      dispatch('clearError');
      commit('setNotifications', []);
      commit('setLoading', true);

      // Configure request
      let request_url = Vue.prototype.$url_api + 'v2/alerts/';

      // Configure URL parameters
      let parameters = payload && payload.params
          ? payload.params
          : StoreMixin.methods.getParametersDefault();
      
      // Build URL with parameters
      request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, parameters);

      // Execute request & return
      let output = false;

      return axios.get(request_url)
      .then((response) => {
         output = response.status == 200;

         if (output) {
            const alerts = response.data.data.alerts || [];
            commit('setNotificationsTotal', response.data.data.total);
            commit('setNotifications', alerts);
        } else {
            commit('setError', 'Algo correu mal. Tente mais tarde ou contacte o suporte.');
        }
    })
      .catch(function (error) {
          commit('setError', 'Algo correu mal. Tente mais tarde ou contacte o suporte.');
          output = false;
      })
      .then(function () {
          commit('setLoading', false);
          return output;
      });
    },

    async readNotification({ commit, dispatch }, payload) {
         // Handle payload data
         let data = payload;
 
         // Configure request
         const request_url = Vue.prototype.$url_api + `v2/alerts/${payload.id}/read`;
 
         let config = {
             method: 'POST',
             url: request_url,
             headers: {
                 'Content-Type': 'application/json'
             },
             data: data
         }
 
         // Execute request & return
         let output = false;
 
         return axios(config)
         .then(function (response) {
             output = response.data.success;
             if (response.status === 204) {
                 output = true;
             } else {
                 commit('setError', 'Algo correu mal. Tente mais tarde ou contacte o suporte.');
                 commit('setHumanError', 'Algo correu mal. Tente mais tarde ou contacte o suporte.');
             }
         })
         .catch(function (error) {
             commit('setError', error.response ? error.response.data.message : error);
             output = false;
         })
         .then(function () {
             return output;
         });
    },

    async getMyNotifications({ dispatch, commit }, payload) {

        // clear state
        dispatch('clearError');

        commit('setMyNotificationsLoading', true);
  
        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/alerts/me';
  
        // Configure URL parameters
        let parameters = payload && payload.params
            ? payload.params
            : StoreMixin.methods.getParametersDefault();
        
        // Build URL with parameters
        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, parameters);
  
        // Execute request & return
        let output = false;
  
        return await axios.get(request_url)
        .then((response) => {
           output = response.status == 200;
  
            if (output) {
                commit('setMyNotificationsTotal', response.data.count);
                commit('setMyNotifications', response.data.data);
            } else {
                commit('setError', 'Algo correu mal. Tente mais tarde ou contacte o suporte.');
                //commit('setHumanError','aaaa');
            }
     })
        .catch(function (error) {
            commit('setError', 'Algo correu mal. Tente mais tarde ou contacte o suporte.');
            //commit('setHumanError', 'aaaa');
            output = false;
        })
        .then(function () {
            commit('setMyNotificationsLoading', false);
            return output;
        });
      },

    async fetchNotificationsDrawer({ dispatch, commit, state }, payload) {
        dispatch('clearError');
        if (!payload || !payload.page) {
            commit('setNotificationsDrawer', []);
        }

        commit('setLoadingNotificationsDrawer', true);

        let request_url = Vue.prototype.$url_api + 'v2/alerts';


        let parameters = payload && payload.params
            ? payload.params
            : StoreMixin.methods.getParametersDefault();

        if (payload && payload.page) {
            parameters.page = payload.page;
        }

        if (!parameters.per_page) {
            parameters.per_page = 10;
        }

        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, parameters);

        // Executa a requisição
        try {
            const response = await axios.get(request_url);
            if (response.status === 200) {
                const notifications = response.data.data.alerts;
                const total = response.data.data.total;

                commit('setNotificationsDrawerTotal', total);

                // Adiciona as notificações ao array existente
                const newNotifications = [...state.notificationsDrawer, ...notifications];
                commit('setNotificationsDrawer', newNotifications);

                return true;
            } else {
                commit('setError', 'Algo correu mal. Tente mais tarde ou contacte o suporte.');
                return false;
            }
        } catch (error) {
            commit('setError', 'Algo correu mal. Tente mais tarde ou contacte o suporte.');
            return false;
        } finally {
            commit('setLoadingNotificationsDrawer', false);
        }
    },

    async fetchMyNotificationsDrawer({ dispatch, commit, state }, payload) {
        dispatch('clearError');
        if (!payload || !payload.page) {
            commit('setMyNotificationsDrawer', []);
        }

        commit('setMyNotificationsDrawerLoading', true);

        let request_url = Vue.prototype.$url_api + 'v2/alerts/me';


        let parameters = payload && payload.params
            ? payload.params
            : StoreMixin.methods.getParametersDefault();

        if (payload && payload.page) {
            parameters.page = payload.page;
        }

        if (!parameters.per_page) {
            parameters.per_page = 10;
        }

        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, parameters);

        // Executa a requisição
        try {
            const response = await axios.get(request_url);
            if (response.status === 200) {
                const notifications = response.data.data.alerts;
                const total = response.data.data.total_alerts;

                commit('setMyNotificationsDrawerTotal', total);

                // Adiciona as notificações ao array existente
                const newNotifications = [...state.myNotificationsDrawer, ...notifications];
                commit('setMyNotificationsDrawer', newNotifications);
                return true;
            } else {
                commit('setError', 'Algo correu mal. Tente mais tarde ou contacte o suporte.');
                return false;
            }
        } catch (error) {
            commit('setError', 'Algo correu mal. Tente mais tarde ou contacte o suporte.');
            return false;
        } finally {
            commit('setMyNotificationsDrawerLoading', false);
        }
    },

    async createNotification({ commit, dispatch }, payload) {
        dispatch('clearError');

        let data = JSON.stringify(payload);

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/alerts';
        let config = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }

        // Execute request & return
        let output = false;

        return axios(config)
            .then(function (response) {
                output = response.status === 201;
                if (response.status === 201) {
                    dispatch('getNotifications', {});
                    output = true;
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                return output;
            });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};