import { render, staticRenderFns } from "./LevelChange.vue?vue&type=template&id=3009376c&scoped=true"
import script from "./LevelChange.js?vue&type=script&lang=js&external"
export * from "./LevelChange.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3009376c",
  null
  
)

export default component.exports