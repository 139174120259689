import Vue from 'vue';
import Vuex from 'vuex';
import axios from '@/plugins/axios';

Vue.use(Vuex);

const state = {
    groups: null
};

const getters = {
    groups: state => state.groups
};

const mutations = {
    setGroups: (state, payload) => { state.groups = payload; }
};

const actions = {
    async getGroups({ commit }, payload) {
        let requestUrl = Vue.prototype.$url_api + 'v1/groups.php';

        await axios
        .get(requestUrl)
        .then(result => {
            commit('setGroups', result.data.data);
        })
        .catch(err => { console.log(err); });
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
