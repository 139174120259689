import axios from '@/plugins/axios';
import Vue from 'vue';
import Vuex from 'vuex';
import StoreMixin from '@/mixins/Store.vue';

Vue.use(Vuex);
Vue.use(StoreMixin);

const state = {
    events: [],
    selectedEvent: null,
    loading: true,
    error: null,
    humanError: null,
    totalEvents: null,
};

const getters = {
    events: (state) => state.events,
    selectedEvent: (state) => state.selectedEvent,
    loading: (state) => state.loading,
    error: (state) => state.error,
    humanError: (state) => state.humanError,
    totalEvents: (state) => state.totalEvents,
};

const mutations = {
    set(state, payload) {
        state.events = payload;
    },
    setLoading(state, payload) {
        state.loading = payload;
    },
    setSelected (state, payload) {
        state.selectedEvent = payload;
    },
    setError(state, payload) {
        state.error = payload;
    },
    setHumanError(state, payload) {
        state.humanError = payload;
    },
    setTotalEvents(state, payload) {
        state.totalEvents = payload;
    },
}

const actions = {
    async get({ commit }, payload) {
        commit('set', []);
        commit('setLoading', true);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/events/activity/';

        // Formulate URL with query string with generateQueryParamsUrl mixin function
        if (payload && payload.params) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        }

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then((response) => {
            output = response.data.success;

            if (output) {
                commit('setTotalEvents', response.data.total);
                commit('set', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            commit('setLoading', false);
            return output;
        });
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
