import { render, staticRenderFns } from "./UserInformation.vue?vue&type=template&id=2b6d616e&scoped=true"
import script from "./UserInformation.js?vue&type=script&lang=js&external"
export * from "./UserInformation.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b6d616e",
  null
  
)

export default component.exports