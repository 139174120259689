import { render, staticRenderFns } from "./TableScoredGameGroups.vue?vue&type=template&id=2d957f84&scoped=true"
import script from "./TableScoredGameGroups.js?vue&type=script&lang=js&external"
export * from "./TableScoredGameGroups.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d957f84",
  null
  
)

export default component.exports