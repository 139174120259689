import Vue from 'vue';
import Vuex from 'vuex';
import axios from '@/plugins/axios';
Vue.use(Vuex);

const state = {
    anomalies: [],
    loading: true,
};

const getters = {
    anomalies: state => state.anomalies,
    loading: state => state.loading,
};

const mutations = {
    setAnomalies: (state, payload) => { state.anomalies = payload },

    setLoading: (state, payload) => { state.loading = payload },

    addAnomaly: (state, payload) => {
        if (! state.anomalies || ! state.anomalies.length)
            state.anomalies = [];
        
        state.anomalies.unshift(payload);
    },

    updateAnomaly: (state, payload) => {
        const index = state.anomalies.findIndex(d => d.id_analysis == payload.id_analysis);

        const updatedItems = [
            ...state.anomalies.slice(0, index),
            payload,
            ...state.anomalies.slice(index + 1)
        ];

        state.anomalies = updatedItems;
    },

    deleteAnomaly: (state, payload) => {
        const index = state.anomalies.findIndex(d => d.id_analysis == payload.id_analysis);
        state.anomalies.splice(index, 1);
    },
};

const actions = {
    async get({ commit }, payload) {
        commit('setLoading', true);
        let requestUrl = Vue.prototype.$url_api + 'v1/analysis.php';

        if (payload) {
            let queryString = '?' + Object.entries(payload).map(([key, val])=>`${key}=${val}`).join('&');
            requestUrl = requestUrl + queryString;
        }

        await axios
        .get(requestUrl)
        .then(result => {
            // Prevent v-datatable from crashing
            if (_.isNull(result.data.data)) {
                result.data.data = [];
            }

            commit('setAnomalies', result.data.data);
        })
        .catch(err => {
            //console.log(err);
        });

        commit('setLoading', false);
    },

    async flag({ commit }, payload) {
        if (payload.flag_analysis == 0) {
            payload.flag_analysis = 1;
        } else {
            payload.flag_analysis = 0;
        }

        // Update UI data
        commit('updateAnomaly', payload);

        // API call
        let requestUrl = Vue.prototype.$url_api + 'v1/analysis.php?id=' + payload.id_analysis;
        //let requestParameters = DataMixin.handleRequestParameters({ action: 'flag' });
        let requestParameters = new URLSearchParams();
        requestParameters.append('action', 'flag');

        await axios
        .put(requestUrl, requestParameters)
        .then(result => {
            //commit('updateAnomaly', result.data.data);
        })
        .catch(err => { console.log(err); });
    },

    clear({commit}) {
        commit('setAnomalies', []);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};