import { render, staticRenderFns } from "./LiveTournaments.vue?vue&type=template&id=2a04fd85&scoped=true"
import script from "./LiveTournaments.js?vue&type=script&lang=js&external"
export * from "./LiveTournaments.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a04fd85",
  null
  
)

export default component.exports