import { render, staticRenderFns } from "./Statistics.vue?vue&type=template&id=79b0bb13&scoped=true"
import script from "./Statistics.js?vue&type=script&lang=js&external"
export * from "./Statistics.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79b0bb13",
  null
  
)

export default component.exports