import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import DataMixin from '@/mixins/Data.vue';
import Hero from '@/components/Hero/Hero.vue';
import TableNotifications from "@/components/Tables/TableNotifications/TableNotifications.vue";
import EmptyStateNoData from '@/components/EmptyStateNoData';
import DialogCreateEditNotification from '@/components/Dialog/DialogCreateEditNotification/DialogCreateEditNotification.vue';

export default {
    data() {
      return {
        dialogues:{
          create: false,
          edit: false,
        },
        isMobile: false,
      };
    },

    components:{
        TableNotifications,
        EmptyStateNoData,
        DialogCreateEditNotification,
        Hero,
    },

    mixins: [
        AuthMixin,
        RailMixin,
        DataMixin,
      ],

    methods: {
      openDialog(){
          this.dialogues.create = true;
      },
      checkScreenSize() {
        this.isMobile = window.innerWidth < 470;
      },
    },
    computed: {
    },
    mounted() {
      this.checkScreenSize();
      window.addEventListener('resize', this.checkScreenSize);
    },

    async created() {
      await this.allowOnlyRoles(['rail_admin', 'rail_manager', 'tracker_admin', 'tracker_manager', 'team_cash_manager']);
  },

    beforeDestroy() {
      window.removeEventListener('resize', this.checkScreenSize);
    },
  }