import axios from '@/plugins/axios';
import Vue from 'vue';
import Vuex from 'vuex';
import StoreMixin from '@/mixins/Store.vue';

Vue.use(Vuex);

const state = {
    data: [],
    selectedEvent: {},
    loading: true,
    error: null,
    humanError: null,
};

const getters = {
    data: state => state.data,
    selectedEvent: state => state.selectedEvent,
    loading: state => state.loading,
    error: state => state.error,
    humanError: state => state.humanError,
};

const mutations = {
    setData: (state, payload) => { state.data = payload; },
    setSelectedEvent: (state, payload) => { state.selectedEvent = payload; },
    updateEvent: (state, payload) => {
        const index = state.data.indexOf(state.data.find(e => e.id == payload.id)); 
        state.data.splice(index, 1, payload);
    },
    setLoading: (state, payload) => { state.loading = payload; },
    setError: (state, payload) => { state.error = payload; },
    setHumanError: (state, payload) => { state.humanError = payload; },
};

const actions = {
    async get({ commit }, payload) {
        commit('setLoading', true);
        let output = false;
        let request_url = Vue.prototype.$calendar_api + 'event/';

        // Formulate URL with query string with generateQueryParamsUrl mixin function
        if (payload) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload);
        }
                
        return axios.get(request_url)
            .then(function (response) {
                output = true;
                commit('setData', response.data.data);
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setLoading', false);
                return output;
            });
    },

    async getById({ commit, dispatch }, id) {
        commit('setLoading', true);
        let output = false;
        let request_url = Vue.prototype.$calendar_api + `event/${id}/`;

        return axios.get(request_url)
            .then(function (response) {
                output = true;
                commit('setSelectedEvent', response.data.data);
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setLoading', false);
                return output;
            });
    },

    async createEvent({ commit }, payload) {
        commit('setLoading', true)

        // Handle payload data 
        let data = JSON.stringify(payload.body)

        // Configure request
        let request_url = Vue.prototype.$calendar_api + 'event/'

        let config = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }

        let output = false

        return axios(config)
            .then(function (response) {
                output = response.data.success;
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setLoading', false);
                return output;
            });

    },

    async updateEvent({ commit }, payload) {
        commit('setLoading', true)

        // Handle payload data 
        let data = JSON.stringify(payload.body)

        // Configure request
        let request_url = Vue.prototype.$calendar_api + `event/${payload.body.eventID}`

        let config = {
            method: 'PUT',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }

        let output = false


        return axios(config)
            .then(function (response) {
                output = response.data.success;
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);                
                output = false;
            })
            .then(function () {
                commit('setLoading', false);
                return output;
            });
    },

    async deleteEvent({commit}, id) {
        commit('setLoading', true)

        let request_url = Vue.prototype.$calendar_api + `event/${id}`

        let config = {
            method: 'DELETE',
            url: request_url, 
            headers: {
                'Content-Type': 'application/json'
            },
        }

        let output = false 

        return axios(config)
            .then(function (response) {
                output = response.data.success;
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setLoading', false);
                return output;
            });

    },

    async rsvpEnroll({commit, dispatch}, payload) {
        commit('setLoading', true);
        let output = false;
        let request_url = Vue.prototype.$calendar_api + `event/${payload.eventID}/rsvp/`;

        let config = {
            method: 'PATCH',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload
        }
        
        return axios(config)
        .then(function (response) {
            commit('updateEvent', response.data.data);
            commit('setSelectedEvent', response.data.data);
            output = true;
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            commit('setLoading', false)
            return output;
        })
    },

    async toggleAttended({commit}, payload) {
        commit('setLoading', true);
        let output = false;
        let request_url = Vue.prototype.$calendar_api + `event/rsvp/${payload.rsvpId}/`

        let config = {
            method: 'PATCH',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: payload
        }
        
        return axios(config)
                .then(function (response) {
                    output = response.data.success;
                })
                .catch(function (error) {
                    commit('setError', error.response ? error.response.data.message : error);
                    commit('setHumanError', error.response ? error.response.data.human_message : error);
                    output = false;
                })
                .then(function () {
                    commit('setLoading', false)
                    return output;
                })
    },

    clearError({ commit }) {
        commit('setError', null);
        commit('setHumanError', null);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};