import { render, staticRenderFns } from "./Reports.vue?vue&type=template&id=6893a4d2&scoped=true"
import script from "./Reports.js?vue&type=script&lang=js&external"
export * from "./Reports.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6893a4d2",
  null
  
)

export default component.exports