import Vue from 'vue';
import Vuex from 'vuex';
import axios from '@/plugins/axios';
import StoreMixin from '@/mixins/Store.vue';
import DataMixin from '@/mixins/Data.vue';

Vue.use(Vuex);
Vue.use(StoreMixin);
Vue.use(DataMixin);

const state = {
    // Associado à  TableUser
    users: [],
    usersActive: [],
    usersInactive: [],
    usersOut: [],

    // Associado a um jogador
    userNotes: [], // estado utilizado por omissão
    userTargetNotes: [], // estado utilizado em UC's como SWAP / Transferência para jogador1
    userStatistics: [],
    userAccess: null,
    userMeta: null,

    userHistoryLevel: {},
    userTeams: [],

    // Associado à  TableUser
    usersTotal: 0,
    usersActiveTotal: 0,
    usersInactiveTotal: 0,
    usersOutTotal: 0,

    // UserDropdown
    usersAllActive : [],

    selectedUser: null,
    selectedUserStatus: null,
    selectedUserProfitShare: null,
    selectedUserNote: null,
    sourceUserRole: [],
    targetUserRole: [],

    // Associado à TableUser
    loading: true,
    usersActiveLoading: true,
    usersInactiveLoading: true,
    usersOutLoading: true,

    // Associado a um jogador
    userLoading: true,
    userHistoryLevelLoading: true,
    userNotesLoading: true,
    sourceUserRoleLoading: true,
    targetUserRoleLoading: true,
    userTeamsLoading: false, // correct way
    userStatisticsLoading: false,
    userAccessLoading: false,
    userMetaLoading: false,

    error: null,
    errorStatus: null,
    humanError: null,
    errorUserMeta: null,
    humanErrorUserMeta: null,

    // TableGroupMembers in Group Single view
    usersFromGroup: [],
    usersFromGroupTotal: null,
    usersFromGroupLoading: true,
    usersFromGroupTotalLoading: true,

    usersFromTeam: [],
    usersFromTeamTotal: null,
    usersFromTeamLoading: true,
    usersFromTeamTotalLoading: true,

    userProgressLevel: null,
    userProgressLevelLoading: false,

    promiseUsersAllActive: null,
    currentPayloadTeamsUserId: null,
    userStatisticError: null,

    userStatisticsHeatmap: null,
    userStatisticsHeatmapLoading: false,
    userStatisticsHeatmapMonthly: null,
    userStatisticsHeatmapMonthlyLoading: false,
    groupChangeRoutineMode: null,

    lastPayloadUserStatistics: {},
    groupChangeRoutineModeError: null,
    groupChangeRoutineModeHumanError: null,
};

const getters = {
    users: (state) => state.users,
    usersActive: (state) => state.usersActive,
    usersInactive: (state) => state.usersInactive,
    usersOut: (state) => state.usersOut,
    usersAllActive: (state) => state.usersAllActive,
    userAccess: (state) => state.userAccess,
    userAccessLoading: (state) => state.userAccessLoading,
    userNotes: (state) => state.userNotes,
    userStatistics: (state) => state.userStatistics,
    userTargetNotes: (state) => state.userTargetNotes,
    userHistoryLevel: (state) => state.userHistoryLevel,
    userTeams: (state) => state.userTeams,
    usersTotal: (state) => state.usersTotal,
    usersActiveTotal: (state) => state.usersActiveTotal,
    usersInactiveTotal: (state) => state.usersInactiveTotal,
    usersOutTotal: (state) => state.usersOutTotal,
    userMeta: (state) => state.userMeta,
    userMetaLoading: (state) => state.userMetaLoading,
    selectedUser: (state) => state.selectedUser,
    selectedUserStatus: (state) => state.selectedUserStatus,
    selectedUserProfitShare: (state) => state.selectedUserProfitShare,
    selectedUserNote: (state) => state.selectedUserNote,
    sourceUserRole: (state) => state.sourceUserRole,
    targetUserRole: (state) => state.targetUserRole,
    loading: (state) => state.loading,
    usersActiveLoading: (state) => state.usersActiveLoading,
    usersInactiveLoading: (state) => state.usersInactiveLoading,
    usersOutLoading: (state) => state.usersOutLoading,
    userLoading: (state) => state.userLoading,
    userTeamsLoading: (state) => state.userTeamsLoading,
    userHistoryLevelLoading: (state) => state.userHistoryLevelLoading,
    userNotesLoading: (state) => state.userNotesLoading,
    userStatisticsLoading: (state) => state.userStatisticsLoading,
    sourceUserRoleLoading: (state) => state.sourceUserRoleLoading,
    targetUserRoleLoading: (state) => state.targetUserRoleLoading,
    error: (state) => state.error,
    errorStatus: (state) => state.errorStatus,
    humanError: (state) => state.humanError,
    errorUserMeta: (state) => state.errorUserMeta,
    humanErrorUserMeta: (state) => state.humanErrorUserMeta,
    usersFromGroup: state => state.usersFromGroup,
    usersFromGroupTotal: state => state.usersFromGroupTotal,
    usersFromGroupLoading: state => state.usersFromGroupLoading,
    usersFromGroupTotalLoading: state => state.usersFromGroupTotalLoading,
    userProgressLevel: state => state.userProgressLevel,
    userProgressLevelLoading: state => state.userProgressLevelLoading,

    usersFromTeamLoading: state => state.usersFromTeamLoading,
    usersFromTeam: state => state.usersFromTeam,
    usersFromTeamTotal: state => state.usersFromTeamTotal,
    usersFromTeamTotalLoading: state => state.usersFromTeamTotalLoading,
    userStatisticError: state => state.userStatisticError,
    userStatisticsHeatmap: state => state.userStatisticsHeatmap,
    userStatisticsHeatmapLoading: state => state.userStatisticsHeatmapLoading,
    userStatisticsHeatmapMonthly: state => state.userStatisticsHeatmapMonthly,
    userStatisticsHeatmapMonthlyLoading: state => state.userStatisticsHeatmapMonthlyLoading,
    groupChangeRoutineMode: state => state.groupChangeRoutineMode,

    groupChangeRoutineModeError: state => state.groupChangeRoutineModeError,
    groupChangeRoutineModeHumanError: state => state.groupChangeRoutineModeHumanError,
};

const mutations = {
    set: (state, payload) => { state.users = payload },
    setUsersActive: (state, payload) => { state.usersActive = payload },
    setUsersInactive: (state, payload) => { state.usersInactive = payload },
    setUsersOut: (state, payload) => { state.usersOut = payload },
    setUsersAllActive: (state, payload) => { state.usersAllActive = payload },
    setUserNotes: (state, payload) => { state.userNotes = payload },
    setUserStatistics: (state, payload) => { state.userStatistics = payload },
    setUserTargetNotes: (state, payload) => { state.userTargetNotes = payload },
    setUserHistoryLevel: (state, payload) => { state.userHistoryLevel = payload },
    setUserTeams: (state, payload) => { state.userTeams = payload },
    setUsersTotal: (state, payload) => { state.usersTotal = payload },
    setUsersActiveTotal: (state, payload) => { state.usersActiveTotal = payload },
    setUsersInactiveTotal: (state, payload) => { state.usersInactiveTotal = payload },
    setUsersOutTotal: (state, payload) => { state.usersOutTotal = payload },
    setSelectedUser: (state, payload) => { state.selectedUser = payload },
    setSelectedUserStatus: (state, payload) => { state.selectedUserStatus = payload },
    setSelectedUserProfitShare:(state, payload) => { state.selectedUserProfitShare = payload },
    setSelectedUserNote: (state, payload) => { state.selectedUserNote = payload },
    setSourceUserRole: (state, payload) => { state.sourceUserRole = payload },
    setTargetUserRole: (state, payload) => { state.targetUserRole = payload },
    setLoading: (state, payload) => { state.loading = payload },
    setUserStatisticsLoading: (state, payload) => { state.userStatisticsLoading = payload },
    setUsersActiveLoading: (state, payload) => { state.usersActiveLoading = payload },
    setUsersInactiveLoading: (state, payload) => { state.usersInactiveLoading = payload },
    setUsersOutLoading: (state, payload) => { state.usersOutLoading = payload },
    setUserLoading: (state, payload) => { state.userLoading = payload },
    setUserTeamsLoading: (state, payload) => { state.userTeamsLoading = payload },
    setUserHistoryLevelLoading: (state, payload) => { state.userHistoryLevelLoading = payload },
    setUserNotesLoading: (state, payload) => { state.userNotesLoading = payload },
    setSourceUserRoleLoading: (state, payload) => { state.sourceUserRoleLoading = payload },
    setTargetUserRoleLoading: (state, payload) => { state.targetUserRoleLoading = payload },
    setError: (state, payload) => { state.error = payload },
    setErrorStatus: (state, payload) => { state.errorStatus = payload },
    setHumanError: (state, payload) => { state.humanError = payload },
    setErrorUserMeta: (state, payload) => { state.errorUserMeta = payload },
    setHumanErrorUserMeta: (state, payload) => { state.humanErrorUserMeta = payload },
    addUserNotes: (state, payload) => {
        if (!state.userNotes || !state.userNotes.length)
            state.userNotes = [];

        state.userNotes.unshift(payload);
    },
    addUserTargetNotes: (state, payload) => {
        if (!state.userTargetNotes || !state.userTargetNotes.length)
            state.userTargetNotes = [];

        state.userTargetNotes.unshift(payload);
    },
    deleteUserNoteById: (state, id) => {
        state.userNotes.splice(
            state.userNotes.findIndex(d => d.id == id),
            1
        );
    },
    deleteUserTargetNoteById: (state, id) => {
        state.userTargetNotes.splice(
            state.userTargetNotes.findIndex(d => d.id == id),
            1
        );
    },
    updateUserNotes: (state, payload) => {
        const index = state.userNotes.findIndex(d => d.id == payload.id);

        const updatedItems = [
            ...state.userNotes.slice(0, index),
            payload,
            ...state.userNotes.slice(index + 1)
        ];

        state.userNotes = updatedItems;
    },

    updateUserTargetNotes: (state, payload) => {
        const index = state.userTargetNotes.findIndex(d => d.id == payload.id);

        const updatedItems = [
            ...state.userTargetNotes.slice(0, index),
            payload,
            ...state.userTargetNotes.slice(index + 1)
        ];

        state.userTargetNotes = updatedItems;
    },

    setPromiseUsersAllActive(state, payload) {
        state.promiseUsersAllActive = payload;
    },

    setUsersFromGroup: (state, payload) => { state.usersFromGroup = payload; },
    setUsersFromGroupTotal: (state, payload) => { state.usersFromGroupTotal = payload; },
    setUsersFromGroupLoading: (state, payload) => { state.usersFromGroupLoading = payload; },
    setUsersFromGroupTotalLoading: (state, payload) => { state.usersFromGroupTotalLoading = payload; },
    setUserProgressLevel: (state, payload) => { state.userProgressLevel = payload; },
    setUserProgressLevelLoading: (state, payload) => { state.userProgressLevelLoading = payload; },
    setCurrentPayloadTeamsUserId: (state, payload) => { state.currentPayloadTeamsUserId = payload; },

    setUsersFromTeamLoading: (state, payload) => { state.usersFromGroupLoading = payload; },
    setUsersFromTeam: (state, payload) => { state.usersFromGroup = payload; },
    setUsersFromTeamTotalLoading: (state, payload) => { state.usersFromTeamTotalLoading = payload; },
    setUsersFromTeamTotal: (state, payload) => { state.usersFromTeamTotal = payload; },
    setUserStatisticError: (state, payload) => { state.userStatisticError = payload; },

    setUserStatisticsHeatmap: (state, payload) => { state.userStatisticsHeatmap = payload; },
    setUserStatisticsHeatmapLoading: (state, payload) => { state.userStatisticsHeatmapLoading = payload; },
    setUserStatisticsHeatmapMonthly: (state, payload) => { state.userStatisticsHeatmapMonthly = payload; },
    setUserStatisticsHeatmapMonthlyLoading: (state, payload) => { state.userStatisticsHeatmapMonthlyLoading = payload; },
    setUserAccess: (state, payload) => { state.userAccess = payload; },
    setUserAccessLoading: (state, payload) => { state.userAccessLoading = payload; },
    setGroupChangeRoutineMode: (state, payload) => { state.groupChangeRoutineMode = payload },
    
    setLastPayloadUserStatistics: (state, payload) => { state.lastPayloadUserStatistics = payload },
    setGroupChangeRoutineModeError: (state, payload) => { state.groupChangeRoutineModeError = payload },
    setGroupChangeRoutineModeHumanError: (state, payload) => { state.groupChangeRoutineModeHumanError = payload },

    setUserMeta: (state, payload) => { state.userMeta = payload },
    setUserMetaLoading: (state, payload) => { state.userMetaLoading = payload },
};

const actions = {
    async get({ commit, dispatch }, payload) {
        // declare variables
        let setItems;
        let setTotalItems;
        let setLoadingItems;
        let handleMutation = StoreMixin.methods.handleUserMutations();

        // Retrieve Object with handleUserMutations mixin function
        if (payload && payload.params) {
            handleMutation = StoreMixin.methods.handleUserMutations(payload.params.status);
        }

        // mutations that will be used to populate states based on status parameter
        setItems = handleMutation.users;
        setTotalItems = handleMutation.total;
        setLoadingItems = handleMutation.loading;

        // clear states
        dispatch('clearErrors');
        commit(setItems, []);
        commit(setTotalItems, 0);
        commit(setLoadingItems, true);
                
        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/users/';

        // Configure URL parameters
        let parameters = payload && payload.params
            ? payload.params
            : StoreMixin.methods.getParametersDefault();
        
        // if status is empty , delete it. (TableUser, 4th tab)
        if (parameters.status === '') delete parameters.status;

        // Build URL with parameters
        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, parameters);

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit(setItems, response.data.data);
                commit(setTotalItems, response.data.total);
            } else {
                commit('setError', response.data.message);
                commit('setErrorStatus', response.status);
            }
        })
        .catch(function (error) {
            commit(setItems, []);
            commit(setTotalItems, 0);
            commit('setError', error.response ? error.response.data.message : error);
            commit('setErrorStatus', error.response.status);
            output = false;
        })
        .then(function () {
            commit(setLoadingItems, false);
            return output;
        });
    },

    async getUsersAllActive({ commit, state}, payload) {
        // avoid multiple calls
        if (state.promiseUsersAllActive) {
            return state.promiseUsersAllActive;
        }

        // Clear state
        commit('setUsersAllActive', []);
        commit('setLoading', true);
        
        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/users/active/';

        // Configure URL Parameters
        let parameters = payload && payload.params
            ? payload.params
            : StoreMixin.methods.getParametersDefault();
    
        // Build URL with parameters
        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, parameters);

        // Execute request & return
        let output = false;

        let request = axios.get(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setUsersAllActive', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setErrorStatus', response.status);
            }
        })
        .catch(function (error) {
            commit('setUsersAllActive', []);
            commit('setError', error.response ? error.response.data.message : error);
            
            output = false;
        })
        .then(function () {
            commit('setLoading', false);
            return output;
        });

        commit('setPromiseUsersAllActive', request);
        return request;
    },

    async getUser({ commit, dispatch }, payload) {
        // Clear state
        commit('setSelectedUser', null);
        commit('setUserLoading', true);

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/users/' + payload;

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setSelectedUser', response.data.data);
                commit('setSelectedUserStatus', response.data.data.status);
                commit('setSelectedUserProfitShare', response.data.data.profitShare);
            } else {
                commit('setError', response.data.message);
                commit('setErrorStatus', response.status);
            }
        })
        .catch(function (error) {
            commit('setSelectedUser', null);
            commit('setError', error.response ? error.response.data.message : error);
            commit('setErrorStatus', error.response.status);
            output = false;
        })
        .then(function () {
            commit('setUserLoading', false);
            return output;
        });
    },

    async getUserMeta({ commit, dispatch }, payload) {
        // Clear state
        commit('setUserMeta', null);
        commit('setUserMetaLoading', true);

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/users/' + payload + '/meta/';

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setUserMeta', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setErrorStatus', response.status);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setErrorStatus', error.response.status);
            output = false;
        })
        .then(function () {
            commit('setUserMetaLoading', false);
            return output;
        });
    },

    async updateUserMeta({ commit, dispatch }, payload) {
        const userID = payload.id;
        delete payload.id;

        // Handle payload data
        let data = JSON.stringify(payload);

        // Configure request_url
        const request_url = Vue.prototype.$url_api + 'v2/users/' + userID + '/meta/';

        // Configure request
        let config = {
            method: 'PUT',
            url: request_url,
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
        };

        // Execute request & return
        let output = false;

        return axios(config)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setUserMeta', response.data.data);
            } else {
                commit('setErrorUserMeta', response.data.message);
                commit('setHumanErrorUserMeta', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setErrorUserMeta', error.response ? error.response.data.message : error);
            commit('setHumanErrorUserMeta', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            return output;
        });
    },

    async getUserChangeRoutineMode({ commit, dispatch }, payload) {
        // Clear state
        commit('setGroupChangeRoutineMode', null);

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/users/' + payload.id + '/group_change_routine_mode';

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setGroupChangeRoutineMode', response.data.data);
            } else {
                commit('setGroupChangeRoutineModeError', response.data.message);
                commit('setGroupChangeRoutineModeHumanError', error.response ? error.response.data.human_message : error);
            }
        })
        .catch(function (error) {
            commit('setGroupChangeRoutineMode', null);
            commit('setGroupChangeRoutineModeError', error.response ? error.response.data.message : error);
            commit('setGroupChangeRoutineModeHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            commit('setUserLoading', false);
            return output;
        });
    },

    // old function but still running
    async getUserRole({ commit, dispatch }, payload) {
        let userRoleState = payload && (payload.state == 'source')
            ? 'setSourceUserRole'
            : 'setTargetUserRole';
        
        let userRoleLoadingState = payload && (payload.state == 'source')
            ? 'setSourceUserRoleLoading'
            : 'setTargetUserRoleLoading';
        
        // Clear state
        commit(userRoleState, null);
        commit(userRoleLoadingState, true);

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/users/' + payload.id;

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit(userRoleState, response.data.data.roles);

                // DEPRECATED
                let isManagement = response.data.data.roles.find(e => e == 'administrator');
                //let isManagement = ! _.isEmpty( this._.intersection(response.data.data.roles, ['tracker_admin', 'tracker_manager']) );

                if (! isManagement) {
                    commit('setSelectedUser', response.data.data);
                } else if (payload.state == 'source'){
                    commit('setSelectedUser', null);
                }
            } else {
                commit('setError', response.data.message);
            }
        })
        .catch(function (error) {
            commit(userRoleState, null);
            commit('setError', error.response ? error.response.data.message : error);
            output = false;
        })
        .then(function () {
            commit(userRoleLoadingState, false);
            return output;
        });
    },

    // new function but not implemented yet
    async getUserRoles({ commit, dispatch }, payload) {

        let userRoleState = payload && (payload.state == 'source')
            ? 'setSourceUserRole'
            : 'setTargetUserRole';
        
        let userRoleLoadingState = payload && (payload.state == 'source')
            ? 'setSourceUserRoleLoading'
            : 'setTargetUserRoleLoading';
        
        // Clear state
        commit(userRoleState, null);
        commit(userRoleLoadingState, true);

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/users/' + payload.id + '/roles';

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit(userRoleState, response.data.data);
            } else {
                commit('setError', response.data.message);
            }
        })
        .catch(function (error) {
            commit(userRoleState, null);
            commit('setError', error.response ? error.response.data.message : error);
            output = false;
        })
        .then(function () {
            commit(userRoleLoadingState, false);
            return output;
        });
    },

    async getUserTeams({ commit, state }, payload) {

        if (state.currentPayloadTeamsUserId == payload) {
            return true;
        } 

        // Clear state
        commit('setUserTeams', []);
        commit('setUserTeamsLoading', true);

        // populates current payload user id
        commit('setCurrentPayloadTeamsUserId', payload);

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/users/' + payload + '/teams';

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setUserTeams', response.data.data);
            } else {
                commit('setError', response.data.message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            output = false;
        })
        .then(function () {
            commit('setUserTeamsLoading', false);
            return output;
        });

    },

    async getUserLevelProgress({ commit, dispatch }, payload) {

        // Clear state
        commit('setUserProgressLevel', null);
        commit('setUserProgressLevelLoading', true);
        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/users/' + payload + '/level';

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;
            if (output) {
                commit('setUserProgressLevel', response.data.data);
            } else {
                commit('setError', response.data.message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            output = false;
        })
        .then(function () {
            commit('setUserProgressLevelLoading', false);
            return output;
        });

    },

    async getUserHistoryLevel({ commit, dispatch }, payload) {
        // Clear state
        commit('setUserHistoryLevel', {});
        commit('setUserHistoryLevelLoading', true);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/users/' + payload.id + '/level/graph';

        // Configure URL parameters
        if (payload && payload.params) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        }

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setUserHistoryLevel', response.data.data);
            } else {
                commit('setError', response.data.message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            output = false;
        })
        .then(function () {
            commit('setUserHistoryLevelLoading', false);
            return output;
        });
    },

    async getUserNotes({ commit, dispatch }, payload) {

        //  populate userNotes based on payload parameter
        let setItems = payload && payload.populateUserNotes
            ? 'setUserNotes'
            : 'setUserTargetNotes'
        
        // Clear state
        commit(setItems, []);
        commit('setUserNotesLoading', true);

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/users/' + payload.id + '/notes/';

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit(setItems, response.data.data);
            } else {
                commit('setError', response.data.message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            output = false;
        })
        .then(function () {
            commit('setUserNotesLoading', false);
            return output;
        });
    },

    async createUserNotes({ commit, dispatch }, payload) {
        // Clear state
        dispatch('clearErrors');

        // Handle payload data
        let data = payload.notes;

        // Configure request
        const request_url = Vue.prototype.$url_api + 'v2/users/' + payload.userID + '/notes/';

        let config = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }

        // Execute request & return
        let output = false;

        return axios(config)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                if (payload.stateToPopulate == 'userNotes') {
                    commit('addUserNotes', response.data.data);
                } else {
                    commit('addUserTargetNotes', response.data.data);
                }
            } else {
                commit('setError', response.data.message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            output = false;
        })
        .then(function () {
            return output;
        });
    },

    async updateUser({ commit, dispatch }, payload) {
        const userID = payload.id;
        delete payload.id;

        // Handle payload data
        let data = JSON.stringify(payload);

        // Configure request_url
        const request_url = Vue.prototype.$url_api + 'v2/users/' + userID;

        // Configure request
        let config = {
            method: 'PUT',
            url: request_url,
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
        };

        // Execute request & return
        let output = false;

        return axios(config)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setSelectedUser', response.data.data);
                dispatch('clearErrors');
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            return output;
        });
    },

    async updateUserChangeRoutineMode({ commit, dispatch }, payload) {
        const userID = payload.id;
        delete payload.id;
        // Handle payload data
        let data = JSON.stringify(payload);

        // Configure request_url
        const request_url = Vue.prototype.$url_api + 'v2/users/' + userID + '/group_change_routine_mode/';

        // Configure request
        let config = {
            method: 'PUT',
            url: request_url,
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
        };

        // Execute request & return
        let output = false;

        return axios(config)
        .then(function (response) {
            output = response.data.success;
            if (output) {
                commit('setGroupChangeRoutineMode', response.data.data);
                dispatch('clearErrors');
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            return output;
        });
    },

    async updateUserTeams({ commit, dispatch }, payload) {
        // clear state
        commit('setUserTeamsLoading', true);
        const userID = payload.id;
        delete payload.id;

        // Handle payload data
        let data = payload.teams;

        // Configure request_url
        const request_url = Vue.prototype.$url_api + 'v2/users/' + userID + '/teams';

        // Configure request
        let config = {
            method: 'PUT',
            url: request_url,
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
        };

        // Execute request & return
        let output = false;

        return axios(config)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setUserTeams', response.data.data.teams);
                dispatch('clearErrors');
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            commit('setUserTeamsLoading', false);
            return output;
        });
    },

    async updateUserNotes({ commit, dispatch }, payload) {
        // clear state
        dispatch('clearErrors');

        // Handle payload data
        let data = payload.notes;

        // Configure request_url
        const request_url = Vue.prototype.$url_api + 'v2/users/' + payload.userID + '/notes/' + payload.noteID;

        // Configure request
        let config = {
            method: 'PUT',
            url: request_url,
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
        };

        // Execute request & return
        let output = false;

        return axios(config)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                //  populate updateUserNotes based on payload parameter
                if (payload.stateToPopulate == 'userNotes') {
                    commit('updateUserNotes', response.data.data);
                } else {
                    commit('updateUserTargetNotes', response.data.data);
                }
                commit('setSelectedUserNote', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            return output;
        });
    },

    async deleteUserNotes({ commit, dispatch }, payload) {
        // clear state
        dispatch('clearErrors');


        // Configure request_url
        const request_url = Vue.prototype.$url_api + 'v2/users/' + payload.userID + '/notes/' + payload.noteID;

        // Execute request & return
        let output = false;

        return axios.delete(request_url)
        .then(function (response) {
            // 204 response status represents success
            output = response.status == 204;

            if (output) {
                //  set deleteUserNoteById based on payload parameter
                if (payload.stateToPopulate == 'userNotes') {
                    commit('deleteUserNoteById', payload.noteID);
                } else {
                    commit('deleteUserTargetNoteById', payload.noteID);
                }
                dispatch('clearErrors');
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            return output;
        });
    },

    async getUsersFromGroup({ commit, dispatch }, payload) {
        // clear state
        dispatch('clearErrors');
        commit('setUsersFromGroup', []);
        commit('setUsersFromGroupTotal', null);
        commit('setUsersFromGroupLoading', true);
        commit('setUsersFromGroupTotalLoading', true);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/users/';

        // Configure URL parameters
        if (payload && payload.params) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        }

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setUsersFromGroup', response.data.data);
                commit('setUsersFromGroupTotal', response.data.total);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            commit('setUsersFromGroupLoading', false);
            commit('setUsersFromGroupTotalLoading', false);
            return output;
        });
    },

    async getUsersFromTeam({ commit, dispatch }, payload) {
        // clear state
        dispatch('clearErrors');
        commit('setUsersFromTeam', []);
        commit('setUsersFromTeamTotal', null);
        commit('setUsersFromTeamLoading', true);
        commit('setUsersFromTeamTotalLoading', true);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/users/';

        // Configure URL parameters
        if (payload && payload.params) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        }

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setUsersFromTeam', response.data.data);
                commit('setUsersFromTeamTotal', response.data.total);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            commit('setUsersFromTeamLoading', false);
            commit('setUsersFromTeamTotalLoading', false);
            return output;
        });
    },

    async getUserStatistics({ commit, dispatch, state }, payload) {

        // validate payload objects before API Call
        let areObjectsEqual = DataMixin.methods.areObjectsEqual(state.lastPayloadUserStatistics, payload);

        if (areObjectsEqual) return false;
        // save last payload
        commit('setLastPayloadUserStatistics', payload);
        
        // Clear state
        commit('setUserStatistics', null);
        commit('setUserStatisticsLoading', true);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/users/' + payload.id + '/statistics/';

        // Formulate URL with query string with generateQueryParamsUrl mixin function
        if (payload.body) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.body);
        }

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setUserStatistics', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setErrorStatus', response.status);
            }
        })
        .catch(function (error) {
            commit('setUserStatistics', null);
            commit('setUserStatisticError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            commit('setUserStatisticsLoading', false);
            return output;
        });
    },

    async getUserStatisticsHeatmap({ commit, dispatch }, payload) {
        // Clear state
        commit('setUserStatisticsHeatmap', null);
        commit('setUserStatisticsHeatmapLoading', true);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/users/' + payload.id + '/sessionsHeatmap/';

        // Formulate URL with query string with generateQueryParamsUrl mixin function
        if (payload.body) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.body);
        }

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setUserStatisticsHeatmap', response.data.data.rows);
            } else {
                commit('setError', response.data.message);
                commit('setErrorStatus', response.status);
            }
        })
        .catch(function (error) {
            commit('setUserStatisticsHeatmap', null);
            commit('setUserStatisticError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            commit('setUserStatisticsHeatmapLoading', false);
            return output;
        });
    },

    async getUserStatisticsHeatmapMonthly({ commit, dispatch }, payload) {
        // Clear state
        commit('setUserStatisticsHeatmapMonthly', null);
        commit('setUserStatisticsHeatmapMonthlyLoading', true);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/users/' + payload.id + '/activityHeatmap/';

        // Formulate URL with query string with generateQueryParamsUrl mixin function
        if (payload.body) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.body);
        }

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setUserStatisticsHeatmapMonthly', response.data.data.rows);
            } else {
                commit('setError', response.data.message);
                commit('setErrorStatus', response.status);
            }
        })
        .catch(function (error) {
            commit('setUserStatisticsHeatmapMonthly', null);
            commit('setUserStatisticError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            commit('setUserStatisticsHeatmapMonthlyLoading', false);
            return output;
        });
    },

    async checkAccessByTrackerId({ commit, dispatch }, payload) {
        // Clear state
        commit('setUserAccess', null);
        commit('setUserAccessLoading', true);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/users/' + payload + '/access/';

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setUserAccess', response.data.data);
            } else {
                commit('setError', response.data.message);
            }
        })
        .catch(function (error) {
            commit('setUserAccess', false);
            output = false;
        })
        .then(function () {
            commit('setUserAccessLoading', false);
            return output;
        });
    },

    async addAccessByTrackerId({ commit, dispatch }, payload) {
        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/users/' + payload + '/access/add';

        // Execute request & return
        let output = false;

        return axios.post(request_url)
        .then(function (response) {
            output = response.data.success;
        })
        .catch(function (error) {
            output = false;
        })
        .then(function () {
            return output;
        });
    },

    async removeAccessByTrackerId({ commit, dispatch }, payload) {
        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/users/' + payload + '/access/remove';

        // Execute request & return
        let output = false;

        return axios.post(request_url)
        .then(function (response) {
            output = response.data.success;
        })
        .catch(function (error) {
            output = false;
        })
        .then(function () {
            return output;
        });
    },

    /**
     * Reset to initial state
     */
    reset({commit, dispatch}) {
        commit('set', []);
        commit('setUsersActive', []);
        commit('setUsersInactive', []);
        commit('setUsersOut', []);
        commit('setUsersAllActive', []);
        commit('setUserNotes', []);
        commit('setUserTeams', []);
        commit('setUserHistoryLevel', {});
        commit('setUsersTotal', 0);
        commit('setUsersActiveTotal', 0);
        commit('setUsersInactiveTotal', 0);
        commit('setUsersOutTotal', 0);
        commit('setSelectedUser', null);
        commit('setSelectedUserNote', null);
        commit('setLoading', true);
        commit('setUsersActiveLoading', true);
        commit('setUsersInactiveLoading', true);
        commit('setUsersOutLoading', true);
        commit('setUserLoading', true);
        commit('setUserTeamsLoading', true);
        commit('setUserHistoryLevelLoading', true);
        commit('setUserNotesLoading', true);
        commit('setPromiseUsersAllActive', null);
        commit('setCurrentPayloadTeamsUserId', null);
        commit('setErrorStatus', null);
        dispatch('clearErrors');
    },

    clearErrors({ commit }) {
        commit('setError', null);
        commit('setHumanError', null);
        commit('setErrorStatus', null);
        commit('setUserStatisticError', null);
        commit('setErrorUserMeta', null);
        commit('setHumanErrorUserMeta', null);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
