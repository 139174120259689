import { render, staticRenderFns } from "./EventLiveDropdown.vue?vue&type=template&id=0ba80139"
import script from "./EventLiveDropdown.js?vue&type=script&lang=js&external"
export * from "./EventLiveDropdown.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports