import Vue from 'vue';
import Vuex from 'vuex';
import axios from '@/plugins/axios';
import StoreMixin from '@/mixins/Store.vue';

Vue.use(Vuex);
Vue.use(StoreMixin);

const state = {
    documents: null,
    singleDocument: null,
    singleDocumentLoading: false,
    loading: false,
    error: null,
    humanError: null,
};

const getters = {
    documents: state => state.documents,
    loading: state => state.loading,
    singleDocument: state => state.singleDocument,
    singleDocumentLoading: state => state.singleDocumentLoading,
    error: state => state.error,
    humanError: state => state.humanError,
};

const mutations = {
    setDocuments: (state, payload) => { state.documents = payload; },
    setLoading: (state, payload) => { state.loading = payload; },
    setError: (state, payload) => { state.error = payload; },
    setHumanError: (state, payload) => { state.humanError = payload; },
    setSingleDocument: (state, payload) => { state.singleDocument = payload; },
    setSingleDocumentLoading: (state, payload) => { state.singleDocumentLoading = payload; },
};

const actions = {
    async getDocumentTypes({ commit, dispatch }, payload) {

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/documents/types/';

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setDocuments', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            commit('setLoading', false);
            return output;
        });
    },

    async getSingleDocument({ commit, dispatch }, payload) {

        // clear states
        dispatch('clearError');
        commit('setSingleDocument', null);
        commit('setSingleDocumentLoading', true);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/documents/';

        // Configure URL Parameters
        let parameters = payload && payload.params
        ? payload.params
        : StoreMixin.methods.getParametersDefault();
            
        // Build URL with parameters
        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, parameters);

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setSingleDocument', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            commit('setSingleDocumentLoading', false);
            return output;
        });
    },

    clearError({ commit }) {
        commit('setError', null);
        commit('setHumanError', null);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
