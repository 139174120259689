import Vue from 'vue';
import Vuex from 'vuex';
import StoreMixin from '@/mixins/Store.vue';
import axios from '@/plugins/axios';

Vue.use(Vuex);
Vue.use(StoreMixin);

const state = {
    countries: [],
    selectedCountry: null,
    loading: true,
    error: null,
    humanError: null,
    totalCountries: null,
};

const getters = {
    countries: (state) => state.countries,
    selectedCountry: (state) => state.selectedCountry,
    loading: (state) => state.loading,
    error: (state) => state.error,
    humanError: (state) => state.humanError,
    totalCountries: (state) => state.totalCountries,
};

const mutations = {
    set(state, payload) {
        state.countries = payload;
    },
    setLoading(state, payload) {
        state.loading = payload;
    },
    setSelected (state, payload) {
        state.selectedCountry = payload;
    },
    setError(state, payload) {
        state.error = payload;
    },
    setHumanError(state, payload) {
        state.humanError = payload;
    },
    setTotalCountries(state, payload) {
        state.totalCountries = payload;
    },
}

const actions = {
    async get({ commit }, payload) {
        commit('set', []);
        commit('setLoading', true);

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/countries/';

        // Formulate URL with query string with generateQueryParamsUrl mixin function
        if (payload && payload.params) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        }

        // Execute request & return
        let output = false;

        return axios.get(request_url)
        .then((response) => {
            output = response.data.success;

            if (output) {
                commit('setTotalCountries', response.data.count);
                commit('set', response.data.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            commit('setHumanError', error.response ? error.response.data.human_message : error);
            output = false;
        })
        .then(function () {
            commit('setLoading', false);
            return output;
        });
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
