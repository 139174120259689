import Vue from 'vue';
import Vuex from 'vuex';
import axios from '@/plugins/axios';
import StoreMixin from '@/mixins/Store.vue';

Vue.use(Vuex);
Vue.use(StoreMixin);

const state = {
    data: [],
    loading: false,
    error: null,
    humanError: null,
};

const getters = {
    data: state => state.data,
    loading: state => state.loading,
    error: state => state.error,
    humanError: state => state.humanError,
};

const mutations = {
    setData: (state, payload) => { state.data = payload; },
    setLoading: (state, payload) => { state.loading = payload; },
    setError: (state, payload) => { state.error = payload; },
    setHumanError: (state, payload) => { state.humanError = payload; },
};

const actions = {
    async get({ commit, dispatch }, payload) {
        // Clear states
        dispatch('reset');
        commit('setLoading', true);
                
        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/networksSharkscope/';

        // Configure URL parameters
        let parameters = payload && payload.params
            ? payload.params
            : StoreMixin.methods.getParametersDefault();

        // Build URL with parameters
        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, parameters);

        // Execute request & return
        let output = false;

        return await axios.get(request_url)
        .then(function (response) {
            output = response.data.success;

            if (output) {
                commit('setData', response.data.data);
            } else {
                commit('setError', response.data.message);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            output = false;
        })
        .then(function () {
            commit('setLoading', false);
            return output;
        });
    },

    /**
     * Reset to initial state
     */
    reset({commit, dispatch}) {
        commit('setData', []);
        commit('setLoading', false);
        commit('setError', null);
        commit('setHumanError', null);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};