import { render, staticRenderFns } from "./DrawerNotificationUser.vue?vue&type=template&id=3be5e692&scoped=true"
import script from "./DrawerNotificationUser.js?vue&type=script&lang=js&external"
export * from "./DrawerNotificationUser.js?vue&type=script&lang=js&external"
import style0 from "./DrawerNotificationUser.vue?vue&type=style&index=0&id=3be5e692&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3be5e692",
  null
  
)

export default component.exports