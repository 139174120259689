import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth';
import DataMixin from '@/mixins/Data';
import AlertError from '@/components/Alerts/AlertError/AlertError.vue';
import UserDropdown from '@/components/Rail/UserDropdown/UserDropdown.vue';
import GroupDropdown from '@/components/Rail/GroupDropdown/GroupDropdown.vue';

import { quillEditor } from 'vue-quill-editor';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.core.css';

export default {
    name: 'DialogCreateEditNotification',

    components: {
        AlertError,
        UserDropdown,
        GroupDropdown,
        quillEditor,
    },

    props: {
        openDialog: {
            type: Boolean,
            required: true
          },
          notification: {
            type: Object,
            required: false
          }
    },

    mixins: [
        AuthMixin,
        DataMixin,
    ],

    data() {
        return this.initialState();
    },

    mounted() {
        this.populateTeamOptions();
    },

    computed: {
        ...mapGetters({
            error: 'groups/error',
            humanError: 'groups/humanError',
            notifications: 'notifications/notifications',
            loading: 'notification/loading',
            groups: 'groups/groups',
        }),
    },

    watch: {
        openDialog: function () {
            if (this.openDialog) {
              if (this.notification) {
                this.form.title = this.notification.title || null;
                this.form.description = this.notification.description || null;
                this.form.selectedOption = this.notification.selectedOption || "all";
              } else {
                this.resetForm();
              }
            }
          },

        notification(newVal){
            if(this.notification){
                this.selectedNotification = this.notification;
            }
        },

        'form.description': function (newVal, oldVal) {
            if(!newVal){
                this.quillError = true;
            }
            else if(newVal && newVal !== oldVal){
                this.quillError = false;
            }
        }
    },

    created() {
        this.initialize();
    },
    
    methods: {
        initialState() {
            return {
                submitting: false,
                form: {
                    title: null,
                    description: null,
                    selectedOption: "all",
                    players: null,
                    groups: null,
                },

                quillOptions: {
                    theme: 'snow',
                    modules: {
                      toolbar: [
                        ['bold', 'italic', 'underline'],
                        [{ header: [1, 2, false] }],
                        [{ list: 'ordered' }, { list: 'bullet' }],
                        ['link'],
                      ],
                    },
                    placeholder: "Insira o texto aqui...",
                },
                selectedTeams: null,
                teamOptions:[],
                quillError: false,
                selectedNotification: this.notification,
                action: null,
                rules: {
                    title: [ 
                        v => !!v || 'Por favor introduza um título para a notificação',
                    ],
                    description: [ 
                        v => !!v || 'Por favor introduza uma imagem para a notificação',
                    ]
                }
            };
        },

        populateTeamOptions() {
            const teamIds = this.$team_ids;
            delete teamIds.cash;
            const options = [];
          
            for (const key in teamIds) {
              if (Array.isArray(teamIds[key])) {
                teamIds[key].forEach(value => {
                  // if string, convert to number
                  const numericValue = typeof value === 'string' ? Number(value) : value;
                  if (!isNaN(numericValue)) {
                    options.push({ text: key, value: numericValue });
                  }
                });
              } else {
                // if its not an array
                const numericValue = typeof teamIds[key] === 'string' ? Number(teamIds[key]) : teamIds[key];
                if (!isNaN(numericValue)) {
                  options.push({ text: key, value: numericValue });
                }
              }
            }
          
            this.teamOptions = options;
        },          

        async initialize() {
            // let payload = {
            //     params: {
            //         page: 1,
            //         itemsPerPage: -1
            //     }
            // }

            // await this.$store.dispatch('notifications/getNotifications', payload);
        },

        async submit() {
            if (!this.$refs.formCreateNotification.validate()) {
                if (!this.form.description) {
                    this.quillError = true;
                    return false;
                }
                return false;
            }

            if (!this.form.description) {
                this.quillError = true;
                return false;
            }

            // Toggle submit button
            this.toggleSubmitting();

            let payload = {
                title: this.form.title,
                description: this.form.description,
            }
            switch(this.form.selectedOption){
                case 'players':
                    payload.players = this.form.players.map(player => player.id);
                    break;
                // case 'groups':
                //     payload.groups = this.form.groups;
                //     break;
                // case 'teams':
                //     payload.teams = this.selectedTeams;
                //     break;
                default:
                    break;
            }

            let result = false;

            if(this.selectedNotification){
                result = await this.$store.dispatch('notifications/update', payload);
                this.action = 'update'
            }else{
                result = await this.$store.dispatch('notifications/createNotification', payload);
                this.action = 'create'
            }
            // Re-enable button
            this.toggleSubmitting();
            // On success
            if (result === true) {
                let message = 'Aviso enviado com sucesso!';
                if (this.action === 'update') {
                     message = 'Aviso editado com sucesso!';
                 }
                this.$store.dispatch('UI/showSnackbar', {
                    message: message,
                    color: 'success'
                });

                await this.$store.dispatch('notifications/getNotifications');
                this.closeDialog();
            }
        },

        /**
         * Used to disable Submit button
         */
        toggleSubmitting() {
            this.submitting = !this.submitting;
        },

        closeDialog() {
            this.$emit("close-dialog");
            this.resetForm();
        },

        resetForm() {
            this.form.title = null;
            this.quillError = false;
            this.form.description = null;
            this.$refs.formCreateNotification.reset();
            // clear errors
            this.$store.dispatch('notifications/clearError');
        }
    },
};