import { render, staticRenderFns } from "./PieChartExpenses.vue?vue&type=template&id=a680140c&scoped=true"
import script from "./PieChartExpenses.js?vue&type=script&lang=js&external"
export * from "./PieChartExpenses.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a680140c",
  null
  
)

export default component.exports