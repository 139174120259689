import { mapGetters } from "vuex";
import DialogNotification from "@/components/Dialog/DialogNotification/DialogNotification.vue";

export default {
  data() {
    return {
      drawer: false,
      dialog: false,
      isFetching: false, // Para evitar carregamentos simultâneos
      page: 1,
    };
  },

  computed: {
    ...mapGetters({
      themeDarkMode: 'UI/themeDarkMode',
      myNotificationsDrawer: 'notifications/myNotificationsDrawer',
      myNotificationsDrawerTotal: 'notifications/myNotificationsDrawerTotal',
      myNotificationsDrawerLoading: 'notifications/myNotificationsDrawerLoading',
      selectedNotification: 'notifications/selectedNotification',
      error: 'notifications/error',
      humanError: 'notifications/humanError',
    })
  },

  components:{
    DialogNotification,
  },

  async mounted() {
    // Escuta o evento global para abrir/fechar o drawer
    this.$root.$on("toggle-drawer-notification-user", (state) => {
      this.drawer = state;
      if (state) {
        this.resetScrollPosition();
      }
    });

    this.fetchData();
  },

  methods: {
    setActiveItem(item) {
      if (item) {
        this.$store.commit(
          'notifications/setSelectedNotification',
          item
        );
      }
    },

    resetScrollPosition() {
      this.$nextTick(() => {
        const drawerElement = this.$refs.drawerContent.children[0].children[0];
        if (drawerElement) {
          drawerElement.scrollTop = 0; // Volta o scroll para o topo
        }
      });
    },

    formatDate(date) {
      const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
      return new Date(date).toLocaleDateString(undefined, options);
    },

    openDialog(notification) {
      this.setActiveItem(notification);
      this.dialog = true;
    },

    async fetchData() {
      const drawerElement = this.$refs.drawerContent.children[0].children[0].children[2];
      if (drawerElement) {
        drawerElement.addEventListener("scroll", this.handleScroll);
      }
      await this.$store.dispatch("notifications/fetchMyNotificationsDrawer", {
        page: this.page,
      });
    },

    async fetchMoreNotifications() {
      if (this.isFetching || this.myNotificationsDrawer.length >= this.myNotificationsDrawerTotal) {
          return;
      }
  
      this.isFetching = true;

      this.page += 1;
      
      await this.$store.dispatch('notifications/fetchMyNotificationsDrawer', {page: this.page})
  
      this.isFetching = false;
    },

    handleScroll(event) {
      const { scrollTop, scrollHeight, clientHeight } = event.target;
      if (scrollTop + clientHeight === scrollHeight && !this.isFetching) {
        this.fetchMoreNotifications();
      }
    },
  },

  beforeDestroy() {
    this.$root.$off("toggle-drawer-notification-user");

    const drawerElement = this.$refs.drawerContent;
    if (drawerElement) {
      drawerElement.removeEventListener("scroll", this.handleScroll);
    }
  },
};