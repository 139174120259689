import { render, staticRenderFns } from "./DialogUserNotes.vue?vue&type=template&id=73544905&scoped=true"
import script from "./DialogUserNotes.js?vue&type=script&lang=js&external"
export * from "./DialogUserNotes.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73544905",
  null
  
)

export default component.exports