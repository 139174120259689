import { render, staticRenderFns } from "./BarChartAvgBuyIn.vue?vue&type=template&id=5863ae8c&scoped=true"
import script from "./BarChartAvgBuyIn.js?vue&type=script&lang=js&external"
export * from "./BarChartAvgBuyIn.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5863ae8c",
  null
  
)

export default component.exports