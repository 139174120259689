import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import Snackbar from '@/components/Snackbar';

export default {

  components: {
    Snackbar,
  },

  mixins: [AuthMixin],

  data() {
    return this.initialState();
  },

  computed: {
    ...mapGetters({
      selectedNotification: 'notifications/selectedNotification',
      myNotifications: 'notifications/myNotifications',
      myNotificationsLoading: 'notifications/myNotificationsLoading',
      loading: 'notifications/loading',
      error: 'notifications/error',
      humanError: 'notifications/humanError',
    }),

    myNotificationsComputed() {
      if (!this.myNotifications || !this.myNotifications.alerts) {
        return [];
      }
    
      return this.myNotifications.alerts
        .filter(notification => !notification.is_open) // Use comparação direta
        .map(notification => {
          if (Array.isArray(notification.alert) && notification.alert.length > 0) {
            return notification.alert[0];
          }
          return {
            id: 1234,
            title: "Erro de notificação",
            description: "Comportamento inesperado. Contacte o suporte.",
            created_at: "2024-12-12 01:38:06",
            created_by: 1
          };
        });
    },
    
  },
      

  props: {
    openDialog: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    openDialog(value) {
      // get my notifications

      this.currentStep = 1; // Reset para o primeiro step
      this.open = value;
      this.confirmNotification = false;
      this.$store.dispatch('notifications/getMyNotifications');
    },
    currentStep(value) {
        this.isRead = false;
    }
  },

  created() {
    this.initialState();
  },

  methods: {
    closeDialog() {
      this.$emit('close-dialog');
    },

    nextStep() {
      if (this.currentStep < this.myNotificationsComputed.length) {
        this.readNotification(this.myNotificationsComputed[this.currentStep - 1].id)

        this.currentStep += 1;
      }
    },

    confirmFinalStep() {
      this.confirmNotification = true;
      this.readNotification(this.myNotificationsComputed[this.currentStep - 1].id, true)

      this.closeDialog();
    },

    async readNotification(id, isFinalStep) {
      if (id) {
        let result = await this.$store.dispatch('notifications/readNotification', {
          id,
        })
        if (result) {

          this.$store.dispatch('UI/showSnackbar', {
            message: isFinalStep ? "Aviso lido com sucesso, a página vai atualizar automaticamente." : "Aviso lido com sucesso!",
            color: 'success'
          });

          if (isFinalStep) {
            // Recarrega a página inteira
            window.location.reload();
          }
        }
      }
      
    },

    initialState() {
      return {
        open: this.openDialog,
        confirmNotification: false,
        currentStep: 1, // Step atual
        isRead: false,
        isLoadingContent: false
      }
    },

    loadingComputed() {
      this.isLoadingContent = true;

      setTimeout(() => {
        this.isLoadingContent = false
      }, 3000); // 3 segundos
        
    },
  },
}
