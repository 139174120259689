import Vue from 'vue';
import Vuex from 'vuex';
import axios from '@/plugins/axios';

Vue.use(Vuex);

const state = {
    departures: [],
    selectedDeparture: null,
    reasonsDeparture: [],
    loading: true
};

const getters = {
    departures: state => state.departures,
    selectedDeparture: state => state.selectedDeparture,
    reasonsDeparture: state => state.reasonsDeparture,
    loading: state => state.loading,
};

const mutations = {
    setDepartures: (state, payload) => { state.departures = payload; },

    setSelectedDeparture: (state, payload) => {
        state.selectedDeparture = payload;
    },

    setReasonsDeparture: (state, payload) => { state.reasonsDeparture = payload; },
    
    setLoading: (state, payload) => { state.loading = payload; },

    addDeparture: (state, payload) => {
        if (! state.departures || ! state.departures.length)
            state.departures = [];
        
        state.departures.unshift(payload);
    },

    updateDeparture: (state, payload) => {
        // For this commented approach to work, await would be necessary, or closeDialog would clear the selectedDeparture
        //const index = state.departures.indexOf(state.selectedDeparture);
        //state.departures[index] = payload[0];

        const index = state.departures.findIndex(d => d.id_departures == payload.id_departures);

        const updatedItems = [
            ...state.departures.slice(0, index),
            payload,
            ...state.departures.slice(index + 1)
        ];

        state.departures = updatedItems;
    },

    deleteDeparture: (state, payload) => {
        const index = state.departures.findIndex(d => d.id_departures == payload.id_departures);
        state.departures.splice(index, 1);
    }
};

const actions = {
    async getDepartures({ commit, dispatch }) {
        dispatch('clear');

        await axios
        .get(Vue.prototype.$url_api + 'v1/departures.php')
        .then(result => {
            commit('setDepartures', result.data.data);
        })
        .catch(err => {
            //console.log(err);
        });

        commit('setLoading', false);
    },

    async getReasonsDeparture({ commit }) {
        await axios
        .get(Vue.prototype.$url_api + 'v1/departures_reasons.php')
        .then(result => {
            commit('setReasonsDeparture', result.data.data);
        })
        .catch(err => {
            //console.log(err);
        });
    },

    async createDeparture({ commit, dispatch }, payload) {        
        // Request
        const requestUrl = Vue.prototype.$url_api + 'v1/departures.php';

        await axios
        .post(requestUrl, payload)
        .then(result => {
            dispatch('getDepartures');
            return true;
        })
        .catch(err => {
            //console.log(err);
            return false;
        });
    },

    async updateDeparture({ state, commit, dispatch }, payload) {
        // Request
        const requestUrl = Vue.prototype.$url_api + 'v1/departures.php?id=' + state.selectedDeparture.id_departures;

        await axios
        .put(requestUrl, payload)
        .then(result => {
            dispatch('getDepartures');
            return true;
        })
        .catch(err => {
            //console.log(err);
            return false;
        });
    },

    async deleteDeparture({ state, commit, dispatch }) {
        let departureToDelete = state.selectedDeparture;    // So we dont have to await

        let requestUrl = Vue.prototype.$url_api + 'v1/departures.php?id=' + state.selectedDeparture.id_departures;
        await axios
        .delete(requestUrl)
        .then(result => {
            dispatch('getDepartures');
            return true;
        })
        .catch(err => {
            //console.log(err);
            commit('setLoading', false);
            return false;
        });
    },
    
    async sendNotificationValueReturnDepartures({ state, commit }, payload) {
        // Request
        const requestUrl = Vue.prototype.$url_api + 'v1/departures.php?id=' + state.selectedDeparture.id_departures + '&action=send_notification_value_return';

        await axios
        .put(requestUrl, payload)
        .then(result => {
            return true;
        })
        .catch(err => {
            //console.log(err);
            return false;
        });
    },

    clear({ commit }) {
        commit('setDepartures', []);
        commit('setLoading', true);
        commit('setSelectedDeparture', null);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
