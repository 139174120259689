import Vue from 'vue';
import Vuex from 'vuex';
import axios from '@/plugins/axios';

Vue.use(Vuex);

const state = {
    nicknames: [],
    selectedNickname: null,
};

const getters = {
    nicknames: state => state.nicknames,
    selectedNickname: state => state.selectedNickname,
};

const mutations = {
    setNicknames: (state, payload) => { state.nicknames = payload; },

    setSelectedNickname: (state, payload) => { state.selectedNickname = payload; },

    addNickname: (state, payload) => {
        if (! state.nicknames || ! state.nicknames.length)
            state.nicknames = [];
        
        state.nicknames.unshift(payload);
    },

    updateNickname: (state, payload) => {
        const index = state.nicknames.findIndex(d => d.id_nicknames == payload.id_nicknames);

        const updatedItems = [
            ...state.nicknames.slice(0, index),
            payload,
            ...state.nicknames.slice(index + 1)
        ];

        state.nicknames = updatedItems;
    },

    deleteNickname: (state, payload) => {
        const index = state.nicknames.findIndex(d => d.id_nicknames == payload.id_nicknames);
        state.nicknames.splice(index, 1);
    }
};

const actions = {
    async get({ commit }, payload) {
        const requestUrl = Vue.prototype.$url_api + 'v1/routes/nicknames.php';

        await axios
        .get(requestUrl)
        .then(result => {
            commit('setNicknames', result.data.data);
            return true;
        })
        .catch(err => {
            console.log(err);
            return false;
        });
    },

    async create({ commit }, payload) {
        const requestUrl = Vue.prototype.$url_api + 'v1/routes/nicknames.php';

        // Convert payload to urlsearchparams
        //payload = this.formulateRequestParameters(payload);
        let params = new URLSearchParams();
        params.append('network', payload.network);
        params.append('user', payload.user);
        params.append('nickname', payload.nickname);

        // Request
        await axios
        .post(requestUrl, params)
        .then(result => {
            commit('addNickname', result.data.data);
            commit('setSelectedNickname', result.data.data);
            return true;
        })
        .catch(err => {
            //console.log(err);
            return false;
        });
    },

    async disable({ commit }, payload) {
        const requestUrl = Vue.prototype.$url_api + 'v1/routes/nicknames.php';

        // Convert payload to urlsearchparams
        let params = new URLSearchParams();
        params.append('action', payload.action);
        params.append('nickname', payload.nickname);

        // Request
        await axios
        .put(requestUrl, params)
        .then((response) => {
            let updatedNickname = state.selectedNickname;
            updatedNickname['flag_nicknames'] = 3;
            commit('updateNickname', updatedNickname);
            return true;
        })
        .catch(err => {
            //console.log(err);
            return false;
        });
    },

    select({commit}, payload) {
        commit('setSelectedNickname', payload);
    },

    /*// TODO
    async update({ state, commit }, payload) {
        const requestUrl = Vue.prototype.$url_api + 'v1/routes/nicknames.php?id=' + state.selectedDeparture.id_departures;

        await axios
        .put(requestUrl, payload)
        .then(result => {
            commit('updateDeparture', result.data.data);
            commit('setSelectedDeparture', result.data.data);
            return true;
        })
        .catch(err => {
            //console.log(err);
            return false;
        });
    },*/

    /*// TODO
    async delete({ state, commit }) {
        let departureToDelete = state.selectedDeparture;    // So we dont have to await

        let requestUrl = Vue.prototype.$url_api + 'v1/routes/nicknames.php?id=' + state.selectedDeparture.id_departures;
        await axios
        .delete(requestUrl)
        .then(result => {
            commit('deleteDeparture', departureToDelete);
            return true;
        })
        .catch(err => {
            //console.log(err);
            return false;
        });
    },*/

    clear({commit}) {
        commit('setNicknames', []);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};