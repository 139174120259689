import { render, staticRenderFns } from "./ApplicationsStatistics.vue?vue&type=template&id=0a3d822e&scoped=true"
import script from "./ApplicationsStatistics.js?vue&type=script&lang=js&external"
export * from "./ApplicationsStatistics.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a3d822e",
  null
  
)

export default component.exports