import { render, staticRenderFns } from "./UserNotes.vue?vue&type=template&id=a84265ee&scoped=true"
import script from "./UserNotes.js?vue&type=script&lang=js&external"
export * from "./UserNotes.js?vue&type=script&lang=js&external"
import style0 from "./UserNotes.vue?vue&type=style&index=0&id=a84265ee&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a84265ee",
  null
  
)

export default component.exports