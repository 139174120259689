import Vue from 'vue';
import axios from '@/plugins/axios';
import Vuex from 'vuex';

Vue.use(Vuex);

const state = {
    data: [],
    loading: true,
    error:  null,
    networksSharkscope: [],
};

const getters = {
    data: state => state.data,
    loading: state => state.loading,
    error: state => state.error,
    networksSharkscope: state => state.networksSharkscope,
};

const mutations = {
    setData: (state, payload) => { state.data = payload; },
    setLoading: (state, payload) => { state.loading = payload; },
    setError: (state, payload) => { state.error = payload; },
    setNetworksSharkscope: (state, payload) => { state.networksSharkscope = payload; },
};

const actions = {
    async get({ commit }) {
        let output = false;
        let request_url = Vue.prototype.$scheduling_api + 'sharkscopeNetworks/';

        return axios.get(request_url)
        .then(function (response) {
            output = true;
            commit('setData', response.data.data);
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.error.message : error);
            output = false;
        })
        .then(function () {
            commit('setLoading', false);
            return output;
        });
    },

    async getNetworksSharkscopeV2({ commit }) {
        let output = false;
        let request_url = Vue.prototype.$url_api + 'v2/networksSharkscope/';

        return axios.get(request_url)
        .then(function (response) {
            output = true;
            commit('setNetworksSharkscope', response.data.data);
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.error.message : error);
            output = false;
        })
        .then(function () {
            commit('setLoading', false);
            return output;
        });
    },

    clearError({ commit }) {
        commit('setError', null);
    },

    // Reset store to its initial state
    reset({ commit }) {
        commit('setData', null);
        commit('setLoading', true);
        commit('setError', null);
        commit('setNetworksSharkscope', []);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};